import {I18n} from "../../../components/I18n";
import React from "react";

export const HeaderSection = ({activity, classes}) => {
    return <>
        <h3 className={classes.title} style={{marginTop: 0}}>
            {I18n.t(activity.competition_type)}
            {activity.activity_type == "organizer" && " Organizator"}
            {activity.activity_type == "pnh" && " PilkaNaHali.pl"}
        </h3>
        <h2 className={classes.title} style={{marginTop: 0}}>
            {activity.localization && activity.localization.city}, {activity.sport && activity.sport.name}{" "}
        </h2>
        <p style={{color: '#777'}}>
            <b>
                {activity.localization && (
                    <>
                        {" "}
                        {activity.localization.name}{" "}
                        {activity.localization.city},{" "}
                        {activity.localization.street}{" "}
                    </>
                )}
                <br/>
                {activity.date} {activity.start_at} - {activity.stop_at}
            </b>
        </p>
    </>
}