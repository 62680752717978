import React, {useCallback} from "react";
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui icons
import Cached from "@material-ui/icons/Cached";
import Check from "@material-ui/icons/Check";
// core components
import Accordion from "components/Accordion/Accordion";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import Clearfix from "components/Clearfix/Clearfix";
import Datetime from "react-datetime";
import moment from "moment";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Api } from "PNH/services/Api";
import { I18n } from "PNH/components/I18n";
import Close from "@material-ui/icons/Close";
import { debounce } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = {
  // ...productsStyle,
  // ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Form = ({
  classes,
  values,
  errors,
  handleSubmit,
  handleReset,
  isSubmitting,
  width,
  ...props
}) => {


  const debounceSubmit = useCallback( debounce(() => {
    if(!isSubmitting) {
      handleSubmit()
    }
  }, 1000), [isSubmitting])

  const setFieldValue = (name, value) => {
    props.setFieldValue(name, value)
    props.setFieldValue("page", 0)
    debounceSubmit()
  }
  const handleChange = (name, value) => {
    props.handleChange(name, value)
    props.setFieldValue("page", 0)
    debounceSubmit()
  }




  return <Card plain>

    {isSubmitting  && <div style={{ position: "absolute", background: "RGBA(255,255,255,0.8)", width:'100%', height: '100%', zIndex: '9999999'}}>
      <GridContainer style={{alignItems: 'center', height:' 100%', textAlign: "center"}}>
        <GridItem xs={12} style={{color: "#19d1a3"}}>
          <CircularProgress color={"inherit"} size={50} thickness={4} />
        </GridItem>
      </GridContainer>
    </div>}

    <CardBody className={classes.cardBodyRefine}>
      <Badges values={values} setFieldValue={setFieldValue} handleSubmit={debounceSubmit}/>
      <Accordion
        active={isWidthUp("sm", width) ? [0] : []}
        activeColor="rose"
        collapses={[
          {
            title: (
              <h4 className={classes.cardTitle}>
                Filtry
                <Clearfix />
              </h4>
            ),
            content: (
              <MainFilters values={values} classes={classes} isSubmitting={isSubmitting} handleChange={handleChange} setFieldValue={setFieldValue} />
            ),
          },
          {
            title: (
              <h4 className={classes.cardTitle}>
                Data
                <Clearfix />
              </h4>
            ),
            content: (
              <DataFilters values={values} classes={classes} isSubmitting={isSubmitting} handleChange={handleChange} setFieldValue={setFieldValue} />
            ),
          },
          {
            title: (
              <h4 className={classes.cardTitle}>
                Godzina
                <Clearfix />
              </h4>
            ),
            content: (
              <TimeFilters values={values} classes={classes} isSubmitting={isSubmitting} handleChange={handleChange} setFieldValue={setFieldValue} />
            ),
          },
          {
            title: (
              <h4 className={classes.cardTitle}>
                Cena
                <Clearfix />
              </h4>
            ),
            content: (
              <PriceFilters values={values} classes={classes}  isSubmitting={isSubmitting} handleChange={handleChange} setFieldValue={setFieldValue} />
            ),
          },
          {
            title: (
              <h4 className={classes.cardTitle}>
                Zaawansowane
                <Clearfix />
              </h4>
            ),
            content: (
              <AdditionalFilters values={values} classes={classes} isSubmitting={isSubmitting} handleChange={handleChange} setFieldValue={setFieldValue} />
            ),
          },
        ]}
      />
      <Button
        fullWidth
        disabled={isSubmitting}
        onClick={async (e) => {
          await setFieldValue("page", 0);
          debounceSubmit();
        }}
        color="primary"
        className={classes.marginAuto}
      >
        Szukaj
      </Button>

      <Tooltip
        id="tooltip-top"
        title="Wyczyść filtry"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          disabled={isSubmitting}
          fullWidth
          link
          size="sm"
          href={window.location.pathname}

          style={{ marginTop: 10 }}
          className={
            classes.pullRight + " " + classes.refineButton
          }
        >
          <Cached /> Wyczyść
        </Button>
      </Tooltip>
    </CardBody>
  </Card>
}

const Badges = ({
  values,
  setFieldValue,
  handleSubmit,
}) => {
  return <GridContainer>
    {Object.keys(values).map(function (objectKey) {
      if (objectKey.startsWith('with_') && !!values[objectKey]) {
        const value = () => {
          if (['with_gender', 'with_competition_type'].includes(objectKey)) {
            return I18n.t(values[objectKey])
          }
          if (objectKey == 'with_lv') {
            return I18n.t(values[objectKey])
          }
          if (objectKey == 'with_coach') {
            return I18n.t(`with_coach_${values[objectKey]}`)
          }
          if (objectKey == 'with_localization_id') {
            return values.localization.label
          }
          if (objectKey == 'with_age') {
            return `${values[objectKey]} lat`
          }
          if (objectKey == 'with_sport_id') {
            return values.sport.name
          }
          if (['with_archive', 'with_free_spots' ,'with_promo', 'with_pay_by_ok_system', 'with_pay_by_benefit', 'with_pay_by_fitprofit', 'with_min_participants'].includes(objectKey)) {
            return I18n.t(objectKey)
          }
          if (['with_start_at_less'].includes(objectKey)) {
            return `Do ${values[objectKey]}`
          }
          if (['with_start_at_greater'].includes(objectKey)) {
            return `Od ${values[objectKey]}`
          }
          if ([ 'with_price_less', 'less'].includes(objectKey)) {
            return `Do ${values[objectKey]} zł`
          }
          if (['with_price_greater', 'with_start_at_greater'].includes(objectKey)) {
            return `Od ${values[objectKey]} zł`
          }
          return values[objectKey]
        }
        return <div style={{
          color: 'white',
          fontSize: '6',
          flexDirection: 'row',
          flexWrap: 'wrap',
          background: '#1ad1a2',
          borderRadius: '20px',
          padding: 3,
          paddingLeft: 10,
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyItems: 'center',
          width: 'fit-content',
          margin: 2,
          cursor: 'pointer',
        }} onClick={() => {
          setFieldValue(objectKey, '')
          if (objectKey == 'with_localization_id') {
            setFieldValue('localization', '')
          }
          if (objectKey == 'with_city') {
            setFieldValue('city', '')
          }

          if (objectKey == 'with_sport_id') {
            setFieldValue('sport', '')
          }
          handleSubmit()
        }}>{value()} <Close style={{ fontSize: 15, fontWeight: 800, marginLeft: 10 }} /></div>

      }
      return null;
    })}
  </GridContainer>
}

const MainFilters = ({
  values,
  handleChange,
  classes,
  isSubmitting,
  setFieldValue,
}) => {
  return <>
    <CustomInput
      labelText="Szukaj..."
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        disabled: isSubmitting,
        type: "with_tags",
        value: values.with_tags,
        name: "with_tags",
        onChange: handleChange,
      }}
    />

    <AsyncSelector
      key={`${values.with_city}-cit`}
      apiMethod={Api.cities.index}
      apiShowMethod={Api.cities.show}
      labelKey="city"
      label="Lokalizacja"
      name="location"
      disabled={isSubmitting}
      defaultValue={values.with_city}
      value={
        values.city && {
          id: values.city.city,
          label: values.city.city,
        }
      }
      onChange={(value, data) => {
        setFieldValue("with_city", value);
        setFieldValue("city", data);
      }}
    />

    <AsyncSelector
      key={`${values.with_localization_id}-loc`}
      apiMethod={({ ...q }) => values.city ? Api.locations.index({ ...q, with_city: values.city.city }) : Api.locations.index(q)}
      apiShowMethod={Api.locations.show}
      labelKey="label"
      label="Obiekt"
      name="location"
      disabled={isSubmitting}
      defaultValue={values.with_localization_id}
      value={
        values.localization && {
          id: values.localization.id,
          label: values.localization.label,
        }
      }
      onChange={(value, data) => {
        setFieldValue("with_localization_id", value);
        setFieldValue("localization", data);
      }}
    />
    <AsyncSelector
      apiMethod={Api.sports.index}
      apiShowMethod={Api.sports.show}
      labelKey="name"
      label="Dyscyplina"
      name="sports"
      disabled={isSubmitting}
      defaultValue={values.with_sport_id}
      value={
        values.sport && {
          id: values.sport.id,
          label: values.sport.name,
        }
      }
      onChange={(value, data) => {
        setFieldValue("with_sport_id", value);
        setFieldValue("sport", data);
      }}
    />
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <InputLabel
        htmlFor="simple-select"
        className={classes.selectLabel}
      >
        Poziom
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={values.with_lv}
        onChange={handleChange}
        inputProps={{
          disabled: isSubmitting,
          name: "with_lv",
        }}
      >
        {[
          "free",
          "beginners",
          "medium",
          "intermediate",
          "advanced",
        ].map((lv) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={lv}
          >
            {I18n.t(lv)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <InputLabel
        htmlFor="simple-select"
        className={classes.selectLabel}
      >
        Płeć
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={values.with_gender}
        onChange={handleChange}
        inputProps={{
          disabled: isSubmitting,
          name: "with_gender",
        }}
      >
        {["males", "females", "unisex"].map((g) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={g}
          >
            {I18n.t(g)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <CustomInput
        labelText="Wiek"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          disabled: isSubmitting,
          type: "number",
          value: values.with_age || '',
          name: "with_age",
          onChange: handleChange,
        }}
    />
  </>
}

const PriceFilters = ({
  values,
  handleChange,
    isSubmitting,
  setFieldValue,
}) => {
  return <>
    <CustomInput
      labelText="Od"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        disabled: isSubmitting,
        type: "numeric",
        value: values.with_price_greater,
        name: "with_price_greater",
        onChange: handleChange,
      }}
    />
    <CustomInput
      labelText="Do"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        disabled: isSubmitting,
        type: "numeric",
        value: values.with_price_less,
        name: "with_price_less",
        onChange: handleChange,
      }}
    />
  </>
}

const DataFilters = ({
  values,
  classes,
  handleChange,
    isSubmitting,
  setFieldValue,
}) => {
  return <>
    <GridContainer>
      <GridItem>
        <InputLabel className={classes.label}>
          Od
        </InputLabel>
        <FormControl fullWidth style={{ zIndex: 25 }}>
          <Datetime
            value={values.with_date_greater}
            locale={moment.locale('pl')}
            dateFormat={"YYYY-MM-DD"}
            timeFormat={false}
            onChange={(value) => {
              const format = moment(value).format("YYYY-MM-DD");
              const date =
                format === "Invalid date" ? value : format;
              setFieldValue("with_date_greater", date);
            }}
            inputProps={{
              disabled: isSubmitting,
              name: "with_date_greater",
              autocomplete: "off",
            }}
          />
        </FormControl>
      </GridItem>
    </GridContainer>
    <InputLabel className={classes.label}>Do</InputLabel>
    <br />
    <FormControl fullWidth style={{ zIndex: 20 }}>
      <Datetime
        locale={moment.locale('pl')}
        value={values.with_date_less}
        dateFormat={"YYYY-MM-DD"}
        timeFormat={false}
        onChange={(value) => {
          const format = moment(value).format("YYYY-MM-DD");
          const date =
            format === "Invalid date" ? value : format;
          setFieldValue("with_date_less", date);
        }}
        inputProps={{
          disabled: isSubmitting,
          name: "with_date_less",
          autocomplete: "off",
        }}
      />
    </FormControl>
  </>
}

const TimeFilters = ({
  values,
  classes,
  handleChange,
    isSubmitting,
  setFieldValue,
}) => {
  return <>
    <CustomInput
      labelText="Od"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        disabled: isSubmitting,
        type: "time",
        value: values.with_start_at_greater || '00:00',
        name: "with_start_at_greater",
        onChange: handleChange,
      }}
    />
    <CustomInput
      labelText="Do"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        disabled: isSubmitting,
        type: "time",
        value: values.with_start_at_less || '23:59',
        name: "with_start_at_less",
        onChange: handleChange,
      }}
    />
  </>
}



const AdditionalFilters = ({
  values,
  classes,
  handleChange,
    isSubmitting,
  setFieldValue,
}) => {
  return <>
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <InputLabel
        htmlFor="simple-select"
        className={classes.selectLabel}
      >
        Dodane przez
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={values.with_activity_type}
        onChange={handleChange}
        inputProps={{
          disabled: isSubmitting,
          name: "with_activity_type",
        }}
      >
        {["pnh", "organizer"].map((g) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={g}
          >
            {I18n.t(g)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <FormControl
        fullWidth
        className={classes.selectFormControl}
    >
      <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
      >
        Rodzaj
      </InputLabel>
      <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={values.with_competition_type}
          onChange={handleChange}
          inputProps={{
            disabled: isSubmitting,
            name: "with_competition_type",
          }}
      >
        {["activity", "sparing"].map((g) => (
            <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={g}
            >
              {I18n.t(g)}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl
        fullWidth
        className={classes.selectFormControl}
    >
      <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
      >
        Z trenerem
      </InputLabel>
      <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={values.with_coach}
          onChange={handleChange}
          inputProps={{
            disabled: isSubmitting,
            name: "with_coach",
          }}
      >
        <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={true}
        >
          Tak
        </MenuItem>
        <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={false}
        >
          Nie
        </MenuItem>
      </Select>
    </FormControl>

    {["pay_by_benefit", "pay_by_ok_system", "pay_by_fitprofit", "promo", "free_spots", "min_participants"].map(
      (p) => (<>
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              disabled={isSubmitting}
              checked={values[`with_${p}`]}
              onClick={() =>
                setFieldValue(`with_${p}`, values[`with_${p}`] ? null : true)
              }
              checkedIcon={
                <Check className={classes.checkedIcon} />
              }
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot,
          }}
          label={I18n.t(p)}
        />
        <br />
      </>
      )
    )}

  </>
}

export default withStyles(styles)(withWidth()(Form));
