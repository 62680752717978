import React from "react";
import PageViewer from "../../../components/Pages/Viewer";
import Accordion from "../../../../components/Accordion/Accordion";
export const DescriptionSection = ({activity}) => {
   return <Accordion
        active={[0]}
        activeColor="rose"
        collapses={[
            {
                title: "Opis",
                content: (
                    <p style={{whiteSpace: "break-spaces"}}>
                        {activity.description}
                        {activity.page_id && activity.page &&
                            <PageViewer pageBody={JSON.parse(activity.page.body)}/>}
                    </p>
                ),
            },
        ]}
    />
}