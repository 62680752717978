/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "PNH/components/Header/Header";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Footer } from "PNH/layouts/Footer";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Danger from "components/Typography/Danger";
import Warning from "components/Typography/Warning";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Hidden from "@material-ui/core/Hidden";
import typographyStyle from "assets/jss/material-kit-pro-react/components/typographyStyle.js";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle";
import { UsersFunctions } from "./index";

import { Api } from "PNH/services/Api";
import { I18n } from "PNH/components/I18n";



class ShoppingCartPage extends React.Component {
  state = {
    summary: false,
    promoCodes: {},
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.fetch();
  }
  fetch = () => {
    Api.basket.index().then(({ ok, data }) => {
      if (ok) {
        this.props.setFieldValue("basket_items", data.rows);
        const promoCodes = {}
        data.rows.forEach((row) => {
          promoCodes[row.id] = row.promo_code

        })
        this.props.setFieldValue("promoCodes", promoCodes);
      }
    });
  };

  getPrice(bi) {
    if (bi.payment_method === "benefit" || bi.payment_method === "ok_system" || bi.payment_method === "fitprofit") {
      return bi.supplement
    }
    return bi.value
  }

  errors = () => {
    if (this.props.values.basket_items.length == 0) {
      return true;
    }

    return (
      this.props.values.basket_items.filter((bi) => {
        if (
          bi.activity_error ||
          bi.spots_error ||
          bi.not_allowed_error ||
          bi.already_participant_error ||
          bi.gender_error ||
          bi.lv_error ||
          bi.payment_method_error || 
          bi.user_lv_error ||
          bi.age_error ||
          bi.team_error ||
          (!!bi.promo_code && !bi.code_valid) ||
          !this.props.data.currentUser.account_completed
        ) {
          return true;
        }
      }).length > 0
    );
  };

  getTransaction = () => {
    Api.transactions.currentUser.basket().then(({ ok, data }) => {
      if (ok) {
        this.props.setFieldValue("transaction", data);
        this.setState({ summary: true });
      } else {
        this.setState({ summary: false }, this.fetch);
      }
    });
  };

  mapFunction = (row, buttonProps = { link: true }) => {
    const { classes, values } = this.props;
    const { promoCodes } = values

    return [
      <div className={classes.imgContainer} key={1}>
        <img src={process.env.REACT_APP_API_URL + row.activity.thumb_image} alt="..." className={classes.img} />
      </div>,
      <div key={row.id} style={{ minWidth: 250 }}>
        <a href={row.activity.url}>
          {row.activity.sport.name} - {row.activity.date}{" "}
          {row.activity.start_at}
        </a>
        <br />
        <small className={classes.tdNameSmall}>
          {row.activity.localization.name} {row.activity.localization.street}{" "}
          {row.activity.localization.city}
        </small>
        <div>
          <small className={classes.tdNameSmall} >
            {row.activity_error && <div  style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia niedostępne</div>}
            {row.payment_method_error && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>Wybierz metodę płatności</div>
            )}
            {row.already_participant_error && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>Jest już uczestnikiem zajęć</div>
            )}
            {row.gender_error && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia tylko dla: {I18n.t(row.activity.gender)}</div>
            )}
            {row.age_error && (
                <div  style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia dla przedziału wiekowego: {row.activity.min_age}-{row.activity.max_age}</div>
            )}
            {(row.lv_error || row.user_lv_error) && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>Zajęcia tylko dla graczy na poziomie: {I18n.t(row.activity.lv)},<br /> poziom gracza: {I18n.t(this.participantLevel(row)) || 'Brak przypisanego poziomu'} </div>
            )}
            {row.spots_error && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>
                Brak wystarczającej ilości miejsc (max:{" "}
                {row.activity.free_spots})
              </div>
            )}
            {row.team_error && (
                <div  style={{fontSize: "7px!important", color: '#f44336'}}>
                  Twója drużyna nie spełnia wymagań
                </div>
            )}
            {row.not_allowed_error && (
              <div  style={{fontSize: "7px!important", color: '#f44336'}}>
                Brak uprawnień do zapisu tej osoby lub konto nie zostało w pełni
                uzupełnione
              </div>
            )}
            {row.promo_code && !row.code_valid && <div  style={{fontSize: "7px!important", color: '#f44336'}}>Kod błędny lub nieaktywny</div>}
          </small>
        </div>
      </div>,

       row.competition_team_id ? row.team.name : (row.participant.id === row.user.id ? `${row.participant.first_name} ${row.participant.last_name}` : row.participant.label),



      row.price + "PLN",
      row.deposit + "PLN",
      row.participant.id === row.user.id ?
        <CustomInput
          labelText="Kod/Voucher"
          error={!!row.promo_code ? !row.code_valid : false}
          success={!!row.promo_code ? row.code_valid : false}
          formControlProps={{
            fullWidth: true,
            disabled: !!row.promo_code,
          }}
          inputProps={{
            type: "text",
            value: promoCodes[row.id],
            name: "with_tags",
            onChange: (e) => {
              let codes = { ...promoCodes, ...{ [row.id]: e.target.value }, }
              this.props.setFieldValue("promoCodes", codes);
            },
          }} />

      : null
      ,
      row.participant.id === row.user.id ? this.promoCodeButton(row, buttonProps) : null,
      <FormControl
        fullWidth
        className={classes.selectFormControl}
        style={{ minWidth: 130 }}
      >
        <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
          Metoda płatności
        </InputLabel>
        <Select
          value={row.payment_method}
          onChange={(e) => {
            
            this.props.setFieldValue("promoCodes", {});
            UsersFunctions.update(
              row.id,
              { payment_method: e.target.value },
              this.props
            ).then(() => {
              this.fetch();
            });
          }}
          MenuProps={{
            className: classes.selectMenu,
            classes: { paper: classes.selectPaper },
          }}
          classes={{ select: classes.select }}
          inputProps={{
            name: "payment_method",
          }}
        >
          {row.available_payment_method.map((pm) => {
            if (!pm.active) {
              return null;
            }
            return (
              <MenuItem
                disabled={!pm.active}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value={pm.name}
              >
                {I18n.t(pm.name)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
      <Tooltip
        id="close1"
        title="Usuń z listy"
        placement="left"
        onClick={() => {
          Api.basket.remove(row.id).then(this.fetch);
        }}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button {...buttonProps} color="danger" className={classes.actionButton}>
          <Close />
        </Button>
      </Tooltip>,
    ];
  };

  participantLevel(row) {
    const level = row.participant.user_sport_levels_attributes.find((lv)=>{
      if(lv.sport_id == row.activity.sport_id) {
        return true
      }
      return false
    })

    return level && level.level 
  }

  promoCodeButton(row, buttonProps) {
    const { classes, values } = this.props;
    const { promoCodes } = values
    if (!!!promoCodes[row.id]) {
      return null;
    }
    return row.promo_code ?
      <Tooltip
        id="close1"
        title="Usuń kod"
        placement="left"
        onClick={(e) => {
          UsersFunctions.update(
            row.id,
            { promo_code: '' },
            this.props
          ).then(() => {
            this.fetch();
          });
        }}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button {...buttonProps} color="danger" className={classes.actionButton}>
          Usuń kod
        </Button>
      </Tooltip> :
      <Tooltip
        id="close1"
        title="Dodaj kod"
        placement="left"
        onClick={(e) => {
          UsersFunctions.update(
            row.id,
            { promo_code: promoCodes[row.id] },
            this.props
          ).then(() => {
            this.fetch();
          });
        }}
        classes={{ tooltip: classes.tooltip }}
      >
        <Button {...buttonProps} color="primary" className={classes.actionButton}>
          Zapisz kod
        </Button>
      </Tooltip>
  }

  renderTransaction = () => {
    const { classes, values, errors, setFieldValue, handleSubmit } = this.props;
    const activeTransaction = this.props.values.transaction;
    return (
      <>
        <h6>
          {" "}
          <a href="#" onClick={() => this.setState({ summary: false })}>
            {"< Powrót"}{" "}
          </a>
        </h6>
        <h4>
          <b> Twoje zamówienie</b>
          <h6>{activeTransaction.created_at}</h6>
        </h4>

        <GridContainer style={{ fontSize: 12 }}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>
                  Zajęcia
                </h6>

                <Table
                  striped
                  tableHead={[
                    <b style={{ fontSize: 12 }}>Użytkownik</b>,
                    <b style={{ fontSize: 12 }}>Typ</b>,
                    <b style={{ fontSize: 12 }}>Cena</b>,
                    <b style={{ fontSize: 12 }}>Dopłata</b>,
                    <b style={{ fontSize: 12 }}>Płatność</b>,
                    <b style={{ fontSize: 12 }}>Kod</b>,
                  ]}
                  tableData={
                    activeTransaction !== false
                      ? activeTransaction.items.map((row) => {
                        if (!(row.item_type === "buy_activity" || row.item_type === "resign_activity")) {
                          return [];
                        };
                        return [
                          `${row.user.first_name} ${row.user.last_name}`,
                          I18n.t(row.item_type),
                          this.getPrice(row) + " PLN",
                          row.supplement > 0 ? 'Tak' : 'Nie',
                          row.item_type === "buy_activity"
                            ? I18n.t(row.payment_method)
                            : "-",
                          row.transaction_item_promo_code ? row.transaction_item_promo_code.code : null
                        ];
                      })
                      : []
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>
                  Opłaty/Doładowania
                </h6>

                <Table
                  striped
                  tableHead={[
                    <b style={{ fontSize: 12 }}>Użytkownik</b>,
                    <b style={{ fontSize: 12 }}>Typ</b>,
                    <b style={{ fontSize: 12 }}>Wartość</b>,

                  ]}
                  tableData={
                    activeTransaction !== false
                      ? activeTransaction.items.map((row) => {
                        if ((row.item_type === "buy_activity" || row.item_type === "resign_activity")) {
                          return [];
                        };
                        return [
                          `${row.user.first_name} ${row.user.last_name}`,
                          I18n.t(row.item_type),
                          row.value + " PLN",

                        ];
                      })
                      : []
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={7}>
            <Card>
              <CardBody>
                <h6 className={classes.cardCategory}>Stan Konta:</h6>
                <b>Główne:</b> {activeTransaction.account_balance_before}zł,{" "}
                <b>Bonus:</b> {activeTransaction.account_bonus_before}zł,{" "}
                <b>Kaucja:</b> {activeTransaction.account_deposit_before}zł
                <br />
                <b>Bonus ważny do:</b> {activeTransaction.user.bonus_expiration_date}{" "}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={5}>
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  checked={values.terms}
                  onClick={() => setFieldValue("terms", !values.terms)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label={
                <span
                  style={{
                    color: !!errors["terms"] ? "red" : "inherit",
                  }}
                >
                  Akceptuję{" "}
                  <a
                    href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                    target="_blank"
                  >
                    postanowienia i regulamin
                  </a>
                  . *
                </span>
              }
            />
            <h4 style={{ fontWeight: 900 }}>
              Do zapłaty przelewem online:{" "}
              <span style={{ color: "rgb(0, 166, 153)" }}>
                {activeTransaction.value} PLN
              </span>
            </h4>
            <Button
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={!values.terms || this.props.isSubmitting}
            >
              Kupuję zajęcia
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  renderTable = () => {
    const { classes } = this.props;
    return (
      <>
        <Hidden only={["xs", "sm"]}>
          <Table
            tableHead={[
              "",
              "Zajęcia",
              "Uczestnik",
              "Cena",
              "Wymagana Kaucja",
              "Kod promocyjny",
              "",
              "Metoda płatności",
              "Metoda płatności",
              "",
            ]}
            tableData={this.props.values.basket_items.map(this.mapFunction)}
          />
        </Hidden>
        <Hidden mdUp>
          <Table
            tableHead={["Zajęcia"]}
            tableData={this.props.values.basket_items
              .map((row) =>
                this.mapFunction(row, {
                  link: false,
                  fullWidth: true,
                  color: "danger",
                })
              )
              .map((r) => {
                return [
                  <GridContainer justify="space-between">
                    <GridItem xs={5}>{r[0]}</GridItem>
                    <GridItem xs={7}>
                      <b>Uczestnik:</b> {r[2]}
                      <br />
                      <b>Cena:</b> {r[3]}
                      <br />
                      <b>Kaucja:</b> {r[4]}
                      <br />
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: 5 }}>
                      <b>{r[1]}</b>
                    </GridItem>


                    <GridItem xs={12}>{r[5]}</GridItem>
                    <GridItem xs={12}>{r[6]}</GridItem>
                    <GridItem xs={12}>{r[7]}</GridItem>
                    <GridItem>{r[8]}</GridItem>
                  </GridContainer >,
                ];
  })
}
/>
        </Hidden >
  <GridContainer>
    <GridItem xs={12} md={7}>
      <Card>
        <CardBody>
          <h6 className={classes.cardCategory}>Stan Konta:</h6>
          <b>Główne:</b> {this.props.data.currentUser.account_balance}zł,{" "}
          <b>Bonus:</b> {this.props.data.currentUser.account_bonus_value}zł,{" "}
          <b>Kaucja:</b>{" "}
          {this.props.data.currentUser.account_deposit_value}
          zł
          <br />
          <b>Bonus ważny do:</b> {this.props.data.currentUser.bonus_expiration_date}
        </CardBody>
      </Card>
    </GridItem>
    <GridItem xs={12} md={5} className={classes.textRight}>
      <div className={classes.textRight}>
        <Button
          color="primary"
          disabled={
            this.errors()

          }
          onClick={() => {
            this.getTransaction();
          }}
        >
          Zobacz podsumowanie
        </Button>
      </div>
    </GridItem>
  </GridContainer>
      </>
    );
  };

render() {
  const { classes, values } = this.props;
  return (
    <div>
      <Header
        key={`bs-${values.basket_items.length}`}
        brand="Klikasz. Grasz."
        fixed
        color="white"
        {...this.props}
      />

      <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              <h3 className={classes.cardTitle}>Koszyk</h3>
              {!this.props.data.currentUser.account_completed && (
                <Card>
                  <CardBody>
                    <h4 className={classes.textCenter}>
                      <Warning>
                        <b>
                          Aby zapisać się na zajęcia, dokończ proces
                          rejestracji. Uzupełnij swój profil i potwierdź nr
                          telefonu.
                        </b>
                      </Warning>
                      <h6 className={classes.textCenter}>
                        {" "}
                        <Button color="primary" href="/edit-profile">
                          Dokończ proces rejestracji
                        </Button>
                      </h6>
                    </h4>
                  </CardBody>
                </Card>
              )}
              {!this.state.summary && this.renderTable()}
              {this.state.summary &&
                this.props.values.transaction.user_id &&
                this.renderTransaction()}
            </CardBody>
          </Card>
        </div>
        <hr />
        <Footer {...this.props} />
      </div>
    </div>
  );
}
}

ShoppingCartPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles({ ...stylesExtended, ...shoppingCartStyle })(
  ShoppingCartPage
);
