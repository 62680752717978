/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page

import { Footer } from "PNH/layouts/Footer";

import { Link } from "react-router-dom";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import {Tabs} from "./Tabs";
import ModalForm from "./EditProfile/modal";
import presentationStyle from "../../../../assets/jss/material-kit-pro-react/views/presentationStyle";

class SubAccountsPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Header brand="PNH" fixed color="white" {...this.props} />

                <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={10}>
                                <h3
                                    style={{
                                        fontWeight: 900,
                                        marginBottom: 40,
                                        marginTop: 30,
                                    }}
                                >
                                    {" "}
                                    Powiązane konta
                                    <h6>
                                        <Link to="/account-settings">Konto</Link> >{" "}
                                        Powiązane konta
                                    </h6>
                                </h3>
                            </GridItem>

                        </GridContainer>
                        <Tabs classes={this.props.classes} {...this.props}/>
                    </div>
                </div>

                <Footer {...this.props} />
            </>
        );
    }
}

SubAccountsPage.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(presentationStyle)(SubAccountsPage);
