/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Api } from "PNH/services/Api";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import Header from "PNH/components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";

import image from "PNH/assets/img/banner.jpg";

class RestorePassword extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  form = (formik) => {
    const { classes, ...rest } = this.props;
    return (
      <>
        <Header absolute color="transparent" brand="PNH" {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="primary"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4 className={classes.cardTitle}>Zmień hasła</h4>
                    </CardHeader>

                    <CardBody signup>
                      <CustomInput
                        id="pass"
                        labelText="Nowe hasło*"
                        noErrorIcon
                        error={formik.errors["password"]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "password",
                          name: "password",
                          value: formik.values.password,
                          onChange: formik.handleChange,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                      <CustomInput
                        id="pass"
                        labelText="Powtórz hasło*"
                        noErrorIcon
                        error={formik.errors["password_confirmation"]}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "password",
                          name: "password_confirmation",
                          value: formik.values.password_confirmation,
                          onChange: formik.handleChange,
                          endAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.label,
                        }}
                        control={
                          <Checkbox
                            value={formik.values.terms}
                            error={formik.errors["terms"]}
                            tabIndex={-1}
                            onClick={() =>
                              formik.setFieldValue(
                                "terms",
                                formik.values.terms === "yes" ? "" : "yes"
                              )
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={
                          <span
                            style={{
                              color: !!formik.errors["terms"]
                                ? "red"
                                : "inherit",
                            }}
                          >
                            Akceptuję{" "}
                            <a
                              href="https://pnh-public.s3.eu-central-1.amazonaws.com/regulamin.pdf"
                              target="_blank"
                            >
                              postanowienia i regulamin
                            </a>
                            . *
                          </span>
                        }
                      />
                    </CardBody>
                    <div className={classes.textCenter}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        onClick={formik.handleSubmit}
                      >
                        Zmień Hasło
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <Formik
        initialValues={{
          password: "",
          password_confirmation: "",
          reset_password_token: this.props.data.urlParams.reset_password_token,
          terms: "",
        }}
        onSubmit={async (values) => {
          Api.users.updatePassord({ user: values }).then((response) => {
            if (response.ok) {
                window.location.href = `/?notification_msg='Zmiana hasła przebiegła pomyślnie'&notification_type=success`;
            } else {
              window.setNotifications([
                {
                  type: "alert",
                  msg: "Błędny email lub hasło",
                },
              ]);
            }
          });
        }}
        validationSchema={Yup.object().shape({
          terms: Yup.string().required("Pole Wymagane"),
          password_confirmation: Yup.string().required("Pole Wymagane"),
          password: Yup.string().required("Pole Wymagane"),
        })}
      >
        {(formik) => {
          return this.form(formik);
        }}
      </Formik>
    );
  }
}

RestorePassword.propTypes = {
  classes: PropTypes.object,
};

const styles = {
  checked: javascriptStyles.checked,
  checkRoot: javascriptStyles.checkRoot,
  checkedIcon: javascriptStyles.checkedIcon,
  uncheckedIcon: javascriptStyles.uncheckedIcon,
};
export default withStyles(javascriptStyles)(
  withStyles(loginPageStyle)(RestorePassword)
);
