import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TablePagination from "@material-ui/core/TablePagination";
import {Api} from "PNH/services/Api";
import CustomInput from "components/CustomInput/CustomInput";
import {withRouter} from 'react-router-dom';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Button from "components/CustomButtons/Button";
class PlayersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 0,
            limit: 10,
            total: 0,
            with_q: "",
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        Api.competition_groups.teams.players( this.props.competitionGroupId, this.props.id,{
            with_q: this.state.with_q,
            page: this.state.page,
            limit: this.state.limit,
        }).then((response) => {
                const tableContent = response.data.rows.map(this.mapRows);
                this.setState({data: tableContent, total: response.data.total});
            });
    };
    mapRows = (row) => {
        const {classes, formik, usersFunctions} = this.props;

        return [
            <> {this.props.admin && <Button size={"sm"} justIcon={true} color={"danger"} style={{marginRight: "30px"}} onClick={(event) => usersFunctions.remove(row.id, row.competition_group_id, this.props.id, formik, () => this.getData())}><CloseOutlinedIcon fontSize={"small"}/> </Button>}{row.user.label}</>,

                <FormControl key={`ur-${row.id}-${row.role}`} fullWidth className={classes.selectFormControl}>
                    <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                    >
                        <span>Rola</span>
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        value={row.role}
                        onChange={(event) => usersFunctions.update(row.id, row.competition_group_id, this.props.id, event.target.value, formik, () => this.getData())}
                        disabled={!this.props.admin}
                        inputProps={{
                            name: "role",
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="capitan"
                        >
                            Kapitan
                        </MenuItem>
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="player"
                        >
                            Gracz
                        </MenuItem>
                    </Select>
                </FormControl>
        ];
    };
    changePage = (event, page) => {
        this.setState({page: page}, this.getData);
    };

    onChangeRowsPerPage = (event) => {
        this.setState({limit: event.target.value, page: 0}, this.getData);
    };

    handleChange = (event) => {
        this.setState(
            {[event.target.name]: event.target.value, page: 0},
            this.getData
        );
    };

    filters = () => {
        const {classes, creator = false} = this.props;
        return (
            <>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <CustomInput
                                        id={`with_q`}
                                        labelText="Szukaj..."
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            placeholder: "Szukaj...",
                                            type: "text",
                                            name: `with_q`,
                                            value: this.state[`with_q`],
                                            onChange: this.handleChange,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </>
        );
    };

    render() {
        const {classes, filters} = this.props;

        return (
            <GridContainer>
                {!filters && this.filters()}
                <GridItem xs={12}>

                    <Table
                        striped
                        tableHead={[
                            <b>Gracz</b>,
                            <b>Rola</b>,
                        ]}
                        tableData={this.state.data}
                    />
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        page={this.state.page}
                        rowsPerPage={this.state.limit}
                        count={this.state.total}
                        onChangePage={this.changePage}
                        onChangeRowsPerPage={this.onChangeRowsPerPage}
                        labelRowsPerPage="Ilość"
                        labelDisplayedRows={({from, to, count}) =>
                            `${from}-${to} z ${count}`
                        }
                    />

                </GridItem>
            </GridContainer>
        );
    }
}

PlayersTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(stylesExtended)(PlayersTable));
