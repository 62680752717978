import ImageGallery from "react-image-gallery";
import React from "react";

export const ImagesSection = ({activity}) => {
    const map = activity.localization
        ? () =>
            `https://maps.googleapis.com/maps/api/staticmap?center=${activity.localization.street}+${activity.localization.city}&zoom=13&scale=1&size=400x500&maptype=roadmap&key=AIzaSyBheXsZwKW1fvo_E-Pr7Y5fFT55dQXXKtw&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x008def%7Clabel:%7C${activity.localization.street}+${activity.localization.city}`
        : () => {
        };
    const images = [
        {
            original: map(),
            thumbnail: map(),
        },
    ];
    if (activity.localization && activity.localization.photos) {
        activity.localization.photos.map((photo) => {
            images.push({
                original: process.env.REACT_APP_API_URL + photo.url,
                thumbnail: process.env.REACT_APP_API_URL + photo.url,
            });
        });
    }
    if (activity.location_object && activity.location_object.photos) {
        activity.location_object.photos.map((photo) => {
            images.push({
                original: process.env.REACT_APP_API_URL + photo.url,
                thumbnail: process.env.REACT_APP_API_URL + photo.url,
            });
        });
    }

    return <ImageGallery
        showFullscreenButton={true}
        showPlayButton={false}
        startIndex={0}
        items={images}
    />
}