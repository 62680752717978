import { create } from "apisauce";
import queryStringParser from "qs";

const apiConnection = create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    'authorization': localStorage.getItem('token'),
  },
});

const loginApiConnection = create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
apiConnection.addMonitor((response) => {
  if (response.status === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('autoAccountCompleteModal');
    window.setNotifications([
      { type: "alert", msg: "Zostałeś wylogowany" },
    ]);
  }
  if (response.status === 403) {
    window.setNotifications([
      { type: "alert", msg: "Brak dostępu" },
    ]);
  }
  // if (response.status === 404) {
  //   window.location.href = '/404'
  // }
});
export const serializeParams = (params = {}) =>
  queryStringParser.stringify(params);

export const Api = {
  competition_groups: {
    teams: {
        index_all: (params) => apiConnection.get(`competition_teams`, params),
        create: (id, params) => apiConnection.post(`competition_groups/${id}/competition_teams`, params),
        show: (id, competition_group_id) => apiConnection.get(`competition_groups/${competition_group_id}/competition_teams/${id}`),
        update: (id, competition_group_id, params) => apiConnection.patch(`competition_groups/${competition_group_id}/competition_teams/${id}`, params),
        players: (competition_group_id, team_id, params) => apiConnection.get(`competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users`, params),
        add_player: ( competition_group_id, id, params) => apiConnection.post(`competition_groups/${competition_group_id}/competition_teams/${id}/competition_teams_users`, params),
        update_player: ( competition_group_id, team_id, id, params) => apiConnection.patch(`competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users/${id}`, params),
        remove_player: (id, competition_group_id, team_id) =>  apiConnection.delete(`competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users/${id}`),
    },
    invitationsCurrentUser: (params) =>
        apiConnection.get(`my_invitations_competition_groups?${serializeParams(params)}`),
      indexCurrentUser: (params) =>
        apiConnection.get(`my_competition_groups?${serializeParams(params)}`),
      index: (params) =>
          apiConnection.get(`competition_groups?${serializeParams(params)}`),
      show: (id) =>
          apiConnection.get(`competition_groups/${id}`),
      preview: (id, params) =>
        apiConnection.get(`competition_groups/${id}/preview?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`competition_groups`, params),
      update: (id, params) => apiConnection.patch(`competition_groups/${id}`, params),
      delete: (id) => apiConnection.delete(`competition_groups/${id}`),
      players: (id,params) =>
        apiConnection.get(`competition_groups/${id}/competition_groups_users?${serializeParams(params)}`),
      add_player: (id, params) => apiConnection.post(`competition_groups/${id}/competition_groups_users`, params),
      update_player: (id, group_id, params) => apiConnection.patch(`competition_groups/${group_id}/competition_groups_users/${id}`, params),
      ask_for_access: (id, params) => apiConnection.post(`competition_groups/${id}/competition_groups_users/current/ask_for_access`, params),
      admin_accept_invitation: (id, group_id, params) => apiConnection.post(`competition_groups/${group_id}/competition_groups_users/${id}/admin_accept_invitation`, params),
      accept_invitation: (id, params) => apiConnection.post(`competition_groups/${id}/competition_groups_users/current/accept_invitation`, params),
      leave_group: (id, params) => apiConnection.delete(`competition_groups/${id}/competition_groups_users/current/leave_group`, params),
      remove_player: (id, competition_group_id) => apiConnection.delete(`competition_groups/${competition_group_id}/competition_groups_users/${id}`),
  },
  audits: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/audits?${serializeParams(params)}`),
    },
  },
  newsletter: {
    create: (params) => apiConnection.post(`newsletters`, params),
  },
  transactions: {
    currentUser: {
      basket: (params) =>
        apiConnection.get(
          `transactions/basket_transactions/1?${serializeParams(params)}`
        ),
      basketCreate: (params) =>
        apiConnection.post(
          `transactions/basket_transactions/?${serializeParams(params)}`
        ),
      index: (params) =>
        apiConnection.get(
          `transactions/current_user_transactions?${serializeParams(
            params
          )}`
        ),
      cancelUnpaidTransaction: (id) =>
        apiConnection.post(
          `transactions/cancel_unpaid_transaction/${id}?`
        ),
    },
    admin: {
      index: (params) =>
        apiConnection.get(
          `admin/transactions?${serializeParams(params)}`
        ),
    },
  },
  basket: {
    index: (params) =>
      apiConnection.get(`basket_items?${serializeParams(params)}`),
    add: (params) => apiConnection.post(`basket_items`, params),
    update: (id, params) =>
      apiConnection.patch(`basket_items/${id}`, params),
    remove: (id) => apiConnection.delete(`basket_items/${id}`),
  },
  activity_templates: {
    index: (params) =>
      apiConnection.get(`activity_templates?${serializeParams(params)}`),
    show: (id) =>
      apiConnection.get(`activity_templates/${id}`),
    delete: (id) => apiConnection.delete(`activity_templates/${id}`),
    create: (params) => apiConnection.post(`activity_templates`, params),
  },
  activities: {
    team_line_ups: {
      build: (id, params) =>
          apiConnection.patch(
              `activities/${id}/activity_team_line_ups/new/build_team?`, params
          ),
      index: (id, params) =>
          apiConnection.get(
              `activities/${id}/activity_team_line_ups?${serializeParams(
                  params
              )}`
          ),
      delete: (id, line_up_id) =>
          apiConnection.delete(
              `activities/${id}/activity_team_line_ups/${line_up_id}`
          ),
      create: (id, params) =>
          apiConnection.post(
              `activities/${id}/activity_team_line_ups?`, params
          ),
    },
    index: (params) =>
      apiConnection.get(`activities?${serializeParams(params)}`),
    show: (id, access_token) =>
      apiConnection.get(`activities/${id}?access_token=${access_token}`),
    resign: (id) => apiConnection.delete(`activities/${id}/resign`),
    resign_subaccount: (id, params) => apiConnection.delete(`activities/${id}/resign_subaccount?${serializeParams(params)}`),
    check_list: (id) =>
      apiConnection.post(`activities/${id}/check_list`),
    delete: (id) => apiConnection.delete(`activities/${id}`),
    create: (params) => apiConnection.post(`activities`, params),
    update: (id, params) =>
      apiConnection.put(`activities/${id}`, params),
    edit_summary:  (id, params) =>
        apiConnection.patch(
            `activities/${id}/edit_summary`,
            params
        ),
    reserveList: {
      create: (activity_id) =>
        apiConnection.post(
          `activities/${activity_id}/reserve_lists`,
          {}
        ),
      resign: (activity_id) =>
        apiConnection.delete(
          `activities/${activity_id}/reserve_lists/1`
        ),
    },
    coordinators: {
      create: (activity_id) =>
        apiConnection.post(
          `activities/${activity_id}/activity_coordinators`,
          {}
        ),
    },
    participants: {
      opinions: {
        all_without_opinion: (params) =>
        apiConnection.get(
          `find_activities_without_opinion?${serializeParams(
            params
          )}`
        ),
        index: (id, params) =>
          apiConnection.get(
            `activities/${id}/user_level_opinions?${serializeParams(
              params
            )}`
          ),
        getLastActivityWithoutOpinion: (params) =>
          apiConnection.get(
            `find_last_activity_without_opinion?${serializeParams(
              params
            )}`
          ),
        markOpinionAsSkipped: (id) =>
          apiConnection.post(
            `mark_opinion_as_skipped`, { id: id }
          ),
        create: (id, params) =>
          apiConnection.post(
            `activities/${id}/user_level_opinions`,
            params
          ),
      },
      index: (id, params) =>
        apiConnection.get(
          `activities/${id}/activity_participants?${serializeParams(
            params
          )}`
        ),

      update: (activity_id, id, params) =>
        apiConnection.patch(
          `activities/${activity_id}/activity_participants/${id}`,
          params
        ),
    },
    admin: {
      participants: {
        index: (id, params) =>
          apiConnection.get(
            `admin/activities/${id}/activity_participants?${serializeParams(
              params
            )}`
          ),
        add: (activity_id, id, add_costs = true) =>
          apiConnection.post(
            `admin/activities/${activity_id}/activity_participants/?add_costs=${add_costs}&user_id=${id}`
          ),
        update: (activity_id, id, params) =>
          apiConnection.patch(
            `admin/activities/${activity_id}/activity_participants/${id}`,
            params
          ),
        delete: (activity_id, id, add_costs = true) =>
          apiConnection.delete(
            `admin/activities/${activity_id}/activity_participants/${id}?add_costs=${add_costs}`
          ),
      },
      check_list: (id) =>
        apiConnection.post(`admin/activities/${id}/check_list`),
      index: (params) =>
        apiConnection.get(`admin/activities?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/activities`, params),
      update: (id, params) =>
        apiConnection.put(`admin/activities/${id}`, params),
      show: (id) => apiConnection.get(`admin/activities/${id}`),
      delete: (id) => apiConnection.delete(`admin/activities/${id}`),
      coordinators: {
        add: (id, params) =>
          apiConnection.post(
            `admin/activities/${id}/add_coordinator`,
            params
          ),
        remove: (id, params) =>
          apiConnection.post(
            `admin/activities/${id}/remove_coordinator`,
            params
          ),
      },
    },
  },
  cities: {
    index: (params) =>
      apiConnection.get(`cities?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`cities/${id}`),
  },
  mainPageTiles: {
    index: (params) =>
      apiConnection.get(`main_page_tiles?${serializeParams(params)}`),
  },
  sports: {
    index: (params) =>
      apiConnection.get(`sports?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`sports/${id}`),
    admin: {
      index: (params) =>
        apiConnection.get(`admin/sports?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/sports`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/sports/${id}`, params),
      show: (id) => apiConnection.get(`admin/sports/${id}`),
      delete: (id) => apiConnection.delete(`admin/sports/${id}`),
    },
  },
  locations: {
    index: (params) =>
      apiConnection.get(`locations?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`locations/${id}`),
    create: (params) => apiConnection.post(`locations`, params),
    update: (id, params) =>
      apiConnection.patch(`locations/${id}`, params),
    admin: {
      delete_image_attachment: (id, location_id) =>
        apiConnection.delete(
          `/admin/locations/${location_id}/delete_image_attachment/${id}`
        ),
      index: (params) =>
        apiConnection.get(`admin/locations?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/locations`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/locations/${id}`, params),
      show: (id) => apiConnection.get(`admin/locations/${id}`),
      delete: (id) => apiConnection.delete(`admin/locations/${id}`),
    },
  },
  pages: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/pages?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/pages`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/pages/${id}`, params),
      show: (id) => apiConnection.get(`admin/pages/${id}`),
      delete: (id) => apiConnection.delete(`admin/pages/${id}`),
    },

    show: (url) => apiConnection.get(`pages?url=${url}`),
  },
  settings: {
    index:  (params) =>
        apiConnection.get(`settings/?${serializeParams(params)}`),
  },
  users: {
    subaccounts: {
      index: (params) =>
          apiConnection.get(`subaccounts/?${serializeParams(params)}`),
      activities: (params) =>
          apiConnection.get(`subaccounts_activities/?${serializeParams(params)}`),
      show: (id) => apiConnection.get(`subaccounts/${id}`),
      update: (id, params) => apiConnection.patch(`subaccounts/${id}`, params),
      create: (params) => apiConnection.post(`subaccounts`, params),
    },
    sport_levels: {
      index: (params) =>
        apiConnection.get(`user_sport_levels/?${serializeParams(params)}`),
      create: (params) =>
        apiConnection.post(`user_sport_levels/`, params),
      destroy: (id) =>
        apiConnection.delete(`user_sport_levels/${id}`),
    },
    index: (params) =>
      apiConnection.get(`users?${serializeParams(params)}`),
    login: (params) => loginApiConnection.post("users/sign_in.json", params),
    FBlogin: (params) => loginApiConnection.post("users/sign_in.json", { fb: true, ...params }),
    logout: () => loginApiConnection.delete("users/sign_out"),
    register: (params) => loginApiConnection.post("users", params),
    updatePassord: (params) => loginApiConnection.put("users/password.json", params),
    confirmAccount: (token) => loginApiConnection.get(`users/confirmation?confirmation_token=${token}`),
    show: (id) => apiConnection.get(`users/${id}`),
    getToken: () =>
      apiConnection.get(`users/confirm_phone_token_sender`),
    confirm_phone_token: (params) =>
      apiConnection.post(`users/confirm_phone_token`, params),
    update: (id, params) => apiConnection.patch(`users/${id}`, params),
    balanceAdd: (params) =>
      apiConnection.post(`transactions/balance_add`, params),
    transactions: (id) =>
      apiConnection.get(`transactions/${id}`),
    bonusValue: (value) =>
      apiConnection.post(`transactions/bonus_value?value=${value}`),
    depositToAdd: () => apiConnection.get(`transactions/deposit_to_add`),
    depositAdd: () => apiConnection.post(`transactions/deposit_add`),
    depositTransfer: () =>
      apiConnection.post(`transactions/deposit_transfer`),
    depositCheck: () => apiConnection.get(`transactions/deposit_ckeck`),
    depositReturn: () =>
      apiConnection.post(`transactions/deposit_return`),
    resetPassword: (email) =>
      apiConnection.post(`users/reset_password`, {email: email}),
    allowToRegister: {
      add: (params) =>
        apiConnection.post(`users/allowed_to_register_add`, params),
      remove: (params) =>
        apiConnection.post(`users/allowed_to_register_remove`, params),
    },
  },
};
