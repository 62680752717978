import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {I18n} from "../../../components/I18n";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AsyncSelector from "../../../components/AsyncSelector/AsyncSelector";
import {Api} from "../../../services/Api";
import {TeamsTab} from "./Teams";

const copyPerCompetitionType = {
    activity: {
        modalTitle: "Dodaj zajęcia do koszyka",
        modalSubTitle: "Wybierz uczestników i graj wraz ze znajomymi!*",
        selectorTitle: "Uczestnicy"
    },
    sparing: {
        modalTitle: "Dodaj sparing do koszyka",
        electorTitle: "Kapitan drużyny",
        modalSubTitle: "Zgłoś swoją drużynę i weź udział w sparingu!*",
    }
}


export const AddToBasketStep = ({ addToBasketModal, activity, values, classes, handleChange, data, players, handleSubmit, isSubmitting, errors, setFieldValue }) => {
    return <>

        <DialogContent
            className={classes.modalBody}
            style={{ width: "100%" }}
        >
            {players.length == 0 && <h3 style={{ textAlign: 'center' }}>
                <b>Oops!</b>
            </h3>}
            {players.length > 0 && <h3 style={{ textAlign: 'center' }}>
                <b>{copyPerCompetitionType[activity.competition_type].modalTitle}</b>
            </h3>}
            <span style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => addToBasketModal(false)}
                >
                    <Close className={classes.modalClose} />
                </Button>
            </span>
            {players.length > 0 && <p style={{ textAlign: 'center' }}>{copyPerCompetitionType[activity.competition_type].modalSubTitle}</p>}
            <GridContainer>
                {activity.competition_type !== "sparing" && <GridItem style={{ marginTop: 20 }}>
                    {players.length > 0 && <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel
                            htmlFor="multiple-select"
                            className={classes.selectLabel}
                        >
                            {copyPerCompetitionType[activity.competition_type].selectorTitle}
                        </InputLabel>
                        <Select
                            multiple
                            value={values.participant_ids}
                            onChange={(e) => {
                                if (
                                    activity.free_spots - e.target.value.length <
                                    0
                                ) {
                                    e.preventDefault();
                                } else {
                                    handleChange(e);
                                }
                            }}
                            MenuProps={{
                                className: classes.selectMenu,
                                classes: { paper: classes.selectPaper },
                            }}
                            classes={{ select: classes.select }}
                            inputProps={{
                                name: "participant_ids",
                            }}
                        >
                            {players.map((u) => {
                                return (
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple,
                                        }}
                                        value={u.id}
                                    >
                                        {u.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>}
                    {players.length == 0 && <>
                        <h6 style={{textAlign: "center", marginTop: '-20px', marginBottom: '50px'}}>
                            <p>Brak osób do zapisania </p>
                        </h6>
                        <p>Możliwe przyczyny:</p>
                        <p>
                            1) Ty lub Twój znajomy jest już zapisany na zajęcia/sparing<br />
                            2) Za niski poziom zaawansowania gracza względem poziomu zajęć<br />
                            3) Poproś znajomego aby w swoim profilu
                            nadał Ci uprawnienia do zapisywania na treningi (
                            <b>Konto -> Bezpieczeństwo -> Ustawienia zapisów na zajęcia</b>{" "}
                            ).<br />
                            4) Na sparing zgłaszasz się jako kapitan i potwierdzasz organizację drużyny
                        </p>
                    </>}
                </GridItem>}

                {players.length > 0 && activity.competition_type !== "sparing" && <GridItem xs={12}>
                    <p>Pozostało wolnych miejsc:{" "}
                        <strong>{activity.free_spots - values.participant_ids.length}</strong>
                    </p>
                </GridItem>}

                {players.length > 0 && <GridItem xs={12} style={{ textAlign: "center", marginTop: 50 }}>

                    {activity.competition_type == "sparing" && <>
                            <h6 style={{textAlign: "left", marginBottom: '-15px', marginTop: '-25px'}}>Wybież drużynę</h6>
                            <AsyncSelector
                                apiMethod={(params) => Api.competition_groups.teams.index_all({with_capitan_id:  data.currentUser.id, with_gender: activity.gender == 'unisex' ? '' : activity.gender })}
                                labelKey="label"
                                label="Drużyna"
                                name="team"
                                onChange={(value, data) => {
                                    setFieldValue('competition_team_id', '')
                                    Api.activities.team_line_ups.build(activity.id, {activity_team_line_up: {competition_team_id: value}}).then((r) => {
                                        setFieldValue('competition_team_id', value)
                                        setFieldValue('competition_group_id', data.competition_group_id)
                                    })

                                }}
                            />

                            {values.competition_team_id && <TeamsTab activity={activity} activityID={activity.id} group_id={values.competition_group_id} team_id={values.competition_team_id} masterFormSetFieldValue={setFieldValue}/>}
                    </>}

                    <Button
                        onClick={handleSubmit}
                        round
                        color="primary"
                        disabled={isSubmitting || (activity.competition_type == 'sparing' && (values.current_team_size < activity.min_team_size || values.current_team_size > activity.max_team_size || !values.competition_team_id))}

                        className={
                            classes.modalSmallFooterFirstButton +
                            " " +
                            classes.modalSmallFooterSecondButton
                        }
                    >
                        Dodaj do koszyka
                    </Button>
                </GridItem>}
                <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                    <Button
                        onClick={() => addToBasketModal(false)}
                        color="transparent"
                        className={classes.modalSmallFooterFirstButton}
                    >
                        Zamknij
                    </Button>
                </GridItem>
                {players.length > 0 && <GridItem xs={12} style={{ textAlign: "left", marginTop: 20 }}>
                    <p style={{ marginTop: 20, fontSize: 11, fontWeight: 800, marginBottom: 0 }}>*Nie możesz znaleźć znajomego? Możliwe przyczyny:</p>
                    <p style={{ fontSize: 11 }}>
                        1) Jest już zapisany na zajęcia/sparing<br />
                        2) Za niski poziom zaawansowania gracza względem poziomu zajęć/sparingu<br />
                        3) Poproś aby w swoim profilu
                        nadał Ci uprawnienia do zapisywania na treningi (
                        <b>Konto -> Bezpieczeństwo -> Ustawienia zapisów na zajęcia</b>{" "}
                        ).
                    </p>
                </GridItem>}
            </GridContainer>

        </DialogContent>
    </>
} 