import React from "react";
import { Link } from "react-router-dom";
import { Api } from "PNH/services/Api";
import ClearIcon from "@material-ui/icons/Clear";
import notificationsModal from "PNH/assets/jss/material-dashboard-pro-react/modalStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";
import Danger from "components/Typography/Danger";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { SimilarActivitiesSlider } from "./ModalSteps/SimilarActivities";
import sectionsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/sectionsStyle";
import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle";
import { Hidden } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class SubaccountResignModal extends React.Component {
  state = {
    resignModal: false,
  };

  constructor(props) {
    super(props);
  }
  resignModal = (open) => {
    this.setState({ resignModal: open });
  };

  renderModal = () => {
    const { classes, activity, data } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={this.state.resignModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.resignModal(false)}
      >
        <DialogTitle disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.resignModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
        <DialogContent className={classes.modalBody} style={{ width: "100%" }} style={{ textAlign: "center", marginTop: 20}}>
            <h3 style={{ textAlign: "center",  marginBottom: 30  }}>
              <b>Wypisujesz z treningu {this.props.subaccount.first_name} {this.props.subaccount.last_name} <br/> potwierdzasz swoją decyzję?</b>
            </h3>
          <h6>
            Ważne! Rezygnacja z zajęć wiąże się z kosztami rezerwacji, szczegóły&nbsp;
            <Link to="/s/odwolanie-zajec-i-rezygnacje-z-treningu" target="_blank">TUTAJ</Link>.
          </h6>
          <GridContainer >
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 50 }}>
              <Button
                onClick={() => this.resignModal(false)}
                color="primary"
                round
                className={classes.modalSmallFooterFirstButton}
              >
                Anuluj!
              </Button>
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                onClick={() => {
                  Api.activities.resign_subaccount(this.props.activity.id, {children_id: this.props.subaccount.id}).then((response) => {
                    if (response.ok) {
                      window.setNotifications([
                        { type: "success", msg: "Wypisano z zajęć" },
                      ]);
                      this.setState({ resignModal: false }, () => {
                        setTimeout(this.props.afterSave, 3000);
                      });
                      this.props.afterSave()
                    } else {
                      window.setNotifications([
                        {
                          type: "alert",
                          msg: "Nie udało się wypisać z zajęć",
                        },
                      ]);
                    }
                  });
                }}
                color="danger"
                simple
                className={
                  classes.modalSmallFooterFirstButton +
                  " " +
                  classes.modalSmallFooterSecondButton
                }
              >
                Tak, wypisuję z zajęć!
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    return (
      <>
        <Button
          color="rose"
          fullWidth={true}
          round
          onClick={() => {
            this.resignModal(true);
          }}
        >
          Wypisz z zajęć: {this.props.subaccount.first_name} {this.props.subaccount.last_name} &nbsp; <ClearIcon />
        </Button>
        {this.renderModal()}
      </>
    );
  }
}

export default withStyles(notificationsModal)(SubaccountResignModal);
