import React from "react";
import * as Yup from "yup";
import moment from "moment";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Błędny adres email"),
  birth_date: Yup.date()
    .typeError("Błędny format daty")
    .min(
      moment
        .utc()
        .subtract(18, "years")
        .format("YYYY-MM-DD"),
      `Musisz mieć ukończone 18 lat`
    )
    .required("Pole Wymagane"),
  city: Yup.string().required("Pole Wymagane"),
  first_name: Yup.string().required("Pole Wymagane"),
  last_name: Yup.string().required("Pole Wymagane"),
});

export default ValidationSchema;
