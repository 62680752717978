export const primaryColor = [
    "#1ad1a2",
    "#16b68e",
    "#11886a",
    "#0b5b47",
    "#062d23",
    "#031712"
];

export const infoColor = [
    "#008def",
    "#0086e6",
    "#0077cc",
    "#0068b3",
    "#005999",
    "#004a80",
    "#003c66",
    "#002d4d"
];
export const roseColor = ["#ff5a5f", "#ff333a", "#ff0008", "#cc0007", "#990005"];
