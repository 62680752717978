import { Api } from "PNH/services/Api";

export const ActiveStep = async (props, setState) => { 
    const { activity } = props;
    const currentUser = props.data.currentUser

    if(activity.competition_type == 'sparing') {
      setState({activeStep: 3})
      return 3
    }

    Api.users.sport_levels.index({with_sport_id: activity.sport.id}).then((r) => {
      const userLevel =  r.data.rows[0]
      if(currentUser.age < activity.min_age || currentUser.age > activity.max_age) {
        setState({activeStep: 3, userLevel: userLevel})
        return  3
      }

      if (activity.lv == 'free' || !!!activity.lv) {
        setState({activeStep: 3, userLevel: userLevel})
        return 3
      }
      if(!!!userLevel) {
        setState({activeStep: 1, userLevel: userLevel})
        return 1
      } else if(userLevel.level !== activity.lv) {
        setState({activeStep: 2, userLevel: userLevel})
        return 2
      } 
      setState({activeStep: 3, userLevel: userLevel})
      return 3
    })
  }