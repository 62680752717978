import React, {useCallback, useEffect, useState} from "react";
import {Api} from "../../../services/Api";
import {UsersForm} from "../CompetitionGroups/Dashboard/Players";
import {SubaccountForm} from "./EditProfile/Form";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import CardBody from "../../../../components/Card/CardBody";
import Card from "../../../../components/Card/Card";
import ModalForm from "./EditProfile/modal";
import Accordion from "../../../../components/Accordion/Accordion";
import ForGroupSlider from "../CompetitionGroups/Dashboard/Activities/ForGroupSlider";
import SubaccountsActivitiesTab from "./Activities";
import ActivitiesSlider from "./Activities/ActivitiesSlider";
import {title} from "../../../assets/jss/material-dashboard-pro-react";

export const Tabs = ({classes, ...props}) => {
    const [children, setChildren] = useState([])

    const fetch = useCallback(() => {
        Api.users.subaccounts.index({limit: 20}).then((r) => {
            if (r.ok) {
                setChildren(r.data.rows)
            }
        })
    })

    useEffect(fetch, []);

    const tabsContent = children.map((c) => {
        return {
            tabName: `${c.first_name}`,
            tabContent: <Accordion nonBorder active={[0,1]} collapses={[
                {
                    title: "Szczegóły",
                    content: <Card><CardBody> <SubaccountForm classes={classes} id={c.id} data={props.data}/></CardBody></Card>
                },
                {
                    title: "Najbliższe zajęcia",
                    content: <Card><CardBody><ActivitiesSlider id={c.id} {...props} /></CardBody></Card>
                },
                {
                    title: "Historia zajęć",
                    content: <SubaccountsActivitiesTab id={c.id} {...props}/>
                },

            ]
            }/>
        }
    })

    if (!tabsContent || tabsContent.length === 0) {
        return <Card style={{marginBottom: '200px'}}><CardBody><GridContainer>
            <GridItem xs={12} style={{textAlign: "center"}}>
                <h5>Obecnie nie posiadasz powiązanych kont</h5>
                <center><ModalForm afterSave={fetch} {...props}/></center>
            </GridItem>
        </GridContainer></CardBody></Card>
    }

    return <GridContainer>
        <GridItem xs={12} style={{position: 'relative', top: '-125px'}}>
            <ModalForm afterSave={fetch} {...props}/>
        </GridItem>
        <GridItem xs={12}>

            <CustomTabs
                headerColor="primary"
                tabs={tabsContent}
            />
        </GridItem>
    </GridContainer>
}