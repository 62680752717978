import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Warning from "../../../components/Typography/Warning";
import {Link} from "react-router-dom";
import Button from "../../../components/CustomButtons/Button";
import ResignModal from "./ResignButton";
import {AddToBasket} from "./AddToBasket";
import ReserveListButton from "./ReserveListButton";
import React from "react";
import Card from "../../../components/Card/Card";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import {makeStyles} from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-kit-pro-react/components/typographyStyle.js";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Badge from "../../../components/Badge/Badge";
import SubaccountResignButton from "./SubaccountResignButton";

const useStyles = makeStyles(styles);

export const PricingBox = ({activity, fetch, updateHeader, classes, ...props}) => {
    return <>
        <Card key={`pbc-${props.uid}`}>
            <CardBody>
                <GridContainer style={{textAlign: "left"}}>
                    <GridItem>
                        {activity.tags && activity.tags.includes("promocja") &&
                            <Badge color={"primary"}>promocja</Badge>}
                        {activity.tags && activity.tags.includes("nowosc") && <Badge color={"primary"}>nowość</Badge>}
                    </GridItem>
                    {activity.event_type !== 'third_type' && notThirdTypeData(activity, classes)}
                    {activity.event_type === 'third_type' && thirdTypeData(activity, classes)}
                </GridContainer>

                <GridContainer style={{textAlign: "center"}}>
                    {activity.id &&
                        !activity.past &&
                        !activity.paid &&
                        activity.user_activity_participants
                            .map((p) => {
                                return p.id;
                            })
                            .includes(props.data.currentUser.id) && (
                            <GridItem>
                                <hr/>
                                <Warning><strong>Transakcja nie opłacona. Dokończ płatność aby zachować rezerwacje
                                    miejsc.</strong></Warning>
                                <br/>
                                <Link
                                    style={{marginRight: 30, marginBottom: 30}}
                                    link
                                    // color="primary"
                                    to={`/transakcje`}
                                >
                                    Moje transkacje
                                </Link>
                                <Button
                                    color="primary"
                                    href={`/dotpay/${activity.transaction_id}`}
                                >
                                    {" "}
                                    Dokończ płatność
                                </Button>
                                <div style={{marginBottom: 30}}></div>
                            </GridItem>
                        )}


                    {activity.id &&
                        activity.free_spots > 0 &&
                        !activity.canceled &&
                        !activity.past && (
                            <GridItem>
                                <hr/>
                                <AddToBasket
                                    activity={activity}
                                    afterSave={() => {
                                        updateHeader()
                                    }}
                                    {...props}
                                />
                            </GridItem>
                        )}
                    {activity.id && (
                        <GridItem>
                            <ReserveListButton
                                activity={activity}
                                afterSave={fetch}
                                {...props}
                            />
                        </GridItem>
                    )}
                    {activity.id && props.data.currentUser.id &&
                        !activity.past && activity.user_activity_participants
                            .filter((p) => {
                                return props.data.currentUser.subaccounts.map(su => su.id).includes(p.id)
                            }).map((subaccount) => {
                                return <GridItem key={`sbr-${subaccount.id}`}>
                                    <hr/>
                                    <SubaccountResignButton
                                        subaccount={subaccount}
                                        activity={activity}
                                        afterSave={updateHeader}
                                        {...props}
                                    />
                                </GridItem>
                            })}
                    {activity.id &&
                        activity.paid &&
                        !activity.past &&
                        activity.user_activity_participants
                            .map((p) => {
                                return p.id;
                            })
                            .includes(props.data.currentUser.id) && (
                            <GridItem>
                                <hr/>
                                <ResignModal
                                    activity={activity}
                                    afterSave={updateHeader}
                                    {...props}
                                />
                            </GridItem>
                        )}
                    {activity.id && activity.canceled && !activity.past && (
                        <GridItem>
                            <hr/>
                            <Danger>
                                <h3>
                                    <b>Odwołane</b>
                                </h3>
                            </Danger>
                        </GridItem>
                    )}
                    {activity.id && !activity.canceled && activity.past && (
                        <GridItem xs={12}>
                            <hr/>
                            <Warning>
                                <h3>
                                    <b>Archiwum</b>
                                </h3>
                            </Warning>
                        </GridItem>
                    )}
                    {activity.pay_by_bonus_account && activity.pay_by_prepaid && <GridItem xs={12}>
                        <hr/>
                        <Primary><strong>Możliwość opłaty z konta bonusowego.</strong></Primary>
                        <br/>
                    </GridItem>}
                    {!activity.pay_by_bonus_account && activity.pay_by_prepaid && <GridItem>
                        <hr/>
                        <Warning><strong>Brak możliwości opłaty z konta bonusowego.</strong></Warning>
                        <br/>
                    </ GridItem>}
                </GridContainer>
            </CardBody>
        </Card>
    </>
}


const notThirdTypeData = (activity, classes) => {
    return (
        <>
            <GridItem>
                <h2><b> <Primary> {activity.price}</Primary></b> <span
                    style={{fontSize: '15px'}}>zł za {activity.competition_type == 'activity' ? "osobę" : "drużynę"}</span>
                </h2>
            </GridItem>
            {(activity.pay_by_benefit || activity.pay_by_ok_system || activity.pay_by_fitprofit) && <GridItem xs={12}>
                <h5>Dostępne zapisy pakietami benefitowymi</h5>
            </GridItem>}

            {activity.pay_by_benefit && <GridItem xs={12} md={4} style={{marginTop: '-30px'}}>
                <Card style={{textAlign: "center"}}>
                    MultiSport: <br/> <b> {activity.supplement || 0.00} PLN </b>
                </Card>
            </GridItem>}
            {activity.pay_by_ok_system && <GridItem xs={12} md={4} style={{marginTop: '-30px'}}>
                <Card style={{textAlign: "center"}}>
                    Medicover Sport: <br/> <b> {activity.supplement_medicover || 0.00} PLN </b>
                </Card>
            </GridItem>}
            {activity.pay_by_fitprofit && <GridItem xs={12} md={4} style={{marginTop: '-30px'}}>
                <Card style={{textAlign: "center"}}>
                    FitProfit: <br/> <b> {activity.supplement_fitprofit || 0.00} PLN </b>
                </Card>
            </GridItem>}

        </>
    )
};


const thirdTypeData = (activity, classes) => {
    return (
        <>

            <GridItem>
                <h2><b> <Primary> {activity.max_price}</Primary></b> <span
                    style={{fontSize: '15px'}}>PLN za {activity.competition_type == 'activity' ? "osobę" : "drużynę"}</span><Danger>
                    <span style={{fontSize: '15px'}}>*</span> </Danger></h2>
            </GridItem>
            {(activity.pay_by_benefit || activity.pay_by_ok_system || activity.pay_by_fitprofit) && <GridItem xs={12}>
                <p style={{fontSize: '15px', color: "#777", marginTop: '25px'}}>Dostępne zapisy pakietami
                    benefitowymi</p>
            </GridItem>}

            {activity.pay_by_benefit && <GridItem xs={12} md={4} style={{marginTop: '-30px'}}>
                <Card style={{textAlign: "center"}}>
                    MultiSport: <br/> <b> {activity.max_benefit_price || 0.00} PLN <Danger> * </Danger> </b>
                </Card>
            </GridItem>}
            {activity.pay_by_fitprofit && <GridItem xs={12} md={4} style={{marginTop: '-30px'}}>
                <Card style={{textAlign: "center"}}>
                    FitProfit: <br/> <b> {activity.max_fitprofit_price || 0.00} PLN <Danger> * </Danger> </b>
                </Card>
            </GridItem>}
            <GridItem>
                <div className={classes.defaultFontStyle}>
                    <Danger> * Podane ceny są cenami maksymalnymi. Ostateczna cena zależna jest od frekwencji na
                        zajęciach. Opłata pobierana na miejscu u Organizatora. Całkowity koszt
                        obiektu: {activity.rental_price || 0.00}</Danger>
                </div>
            </GridItem>
        </>
    );
};


function Primary(props) {
    const {children} = props;
    const classes = useStyles();
    return (
        <div className={classes.primaryText}>
            {children}
        </div>
    );
}

function Danger(props) {
    const {children} = props;
    const classes = useStyles();
    return (
        <div className={classes.dangerText}>
            {children}
        </div>
    );
}