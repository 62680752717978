import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import {Form} from "./Form";
import {Api} from "PNH/services/Api";

export const UsersFunctions = {
    show: (id, formik) => {
        return Api.users.subaccounts.show(id).then((response) => {
            if (response.ok) {
                formik.setValues({
                    ...UsersFunctions.defaultValues(formik.props),
                    ...{wasAccepted: response.data.child_policy_accepted},
                    ...response.data,
                });


                return response
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Coś poszło nie tak."},
                ]);
            }
        });
    },

    update: (values, formik) => {
        return Api.users.subaccounts.update(values.id, {user: values}).then((response) => {
            if (response.ok) {
                formik.setValues({
                    ...UsersFunctions.defaultValues(formik.props),
                    ...{wasAccepted: response.data.child_policy_accepted},
                    ...response.data,
                });
                window.setNotifications([{type: "success", msg: "Zapisano zmiany"}]);
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Nie udało się zapisać konta. Sprawdź poprawność danych."},
                ]);
            }
        });
    },

    create: (values, formik) => {
        return Api.users.subaccounts.create({user: values}).then((response) => {
            if (response.ok) {
                formik.setValues({
                    ...UsersFunctions.defaultValues(formik.props),
                    ...{wasAccepted: response.data.child_policy_accepted},
                    ...response.data,
                });
                formik.props.data.getCurrentUser()
                window.setNotifications([{type: "success", msg: "Utworzono konto"}]);
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Nie udało się dodać konta. Sprawdź poprawność danych."},
                ]);
            }
        });
    },

    defaultValues: (props) => {
        return {
            id: "",
            identifier: "",
            first_name: "",
            last_name: "",
            email: "",
            birth_date: "",
            city: "",
            gender: "",
            wasAccepted: false,
            child_policy_accepted: false,
            roles: [],

            afterSave: props?.afterSave,
        };
    },
};

export const SubaccountForm = withFormik({
    enableReinitialize: false,
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
        return {...UsersFunctions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        formikProps.setSubmitting(true);

        if (values.id) {
            return UsersFunctions.update(values, formikProps).then(() => {
                formikProps.setTouched({});
            });
        } else {
           return UsersFunctions.create(values, formikProps).then(() => {
                formikProps.setTouched({});
                values.afterSave();
            });
        }
    },
})(Form);
