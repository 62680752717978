/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Pagination from '@material-ui/lab/Pagination';
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Tooltip from "@material-ui/core/Tooltip";
import Primary from "components/Typography/Primary";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle";
import Form from "./Form";
import ActivityCard from "PNH/components/Activity/ActivityCard";
import { ActivitiesFunctions } from "./index";
import MainSlider from "PNH/views/MainPage/Sections/ActivitiesSliders/MainSlider"
import { Link } from "react-router-dom";
import Placeholder from "assets/img/image_placeholder.jpg"
import Cached from "@material-ui/icons/Cached";
import CircularProgress from "@material-ui/core/CircularProgress";

class SectionProducts extends React.Component {
  componentDidMount() {
    this.props.handleSubmit()
  }
  render() {
    const { classes, values, isSubmitting } = this.props;

    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <h2>Znajdź coś dla siebie</h2>
          <GridContainer>
            <GridItem md={3} sm={3}>
              <Form {...this.props} />
            </GridItem>
            <GridItem md={9} sm={9} id="search-box">

              {isSubmitting && <div style={{textAlign: "center"}}>
                <GridContainer>
                  <GridItem md={12} style={{color: "#19d1a3"}}>
                    <CircularProgress color={"inherit"} size={50} thickness={4} />
                  </GridItem>
                </GridContainer>
              </div>
              }

              {this.props.values.data.total === 0 && !isSubmitting && (
                <>
                  <p style={{ marginLeft: 30 }}>
                    <Primary>
                      <h3>
                        <b>Brak dokładnych dopasowań.</b>
                      </h3>

                    </Primary>
                    <h5>Zmień lub usuń niektóre filtry albo sprawdź nasze najbliższe zajęcia</h5>
                  </p>

                  <Suspense fallback={<img src={Placeholder} alt="placeholder" />}>
                    <MainSlider {...this.props} smItems={1} mdItems={1} lgItems={2} xlItems={2} size={12}/>
                  </Suspense>
                  <p style={{textAlign: 'center'}}>
                    <Tooltip
                    id="tooltip-top"
                    title="Wyczyść filtry"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      round
                      size="sm"
                      href={window.location.pathname}
                    >
                      <Cached /> Wyczyść filtry
                    </Button>
                  </Tooltip>
                  </p>
                </>
              )}
              {this.props.values.data.total > 0 && !isSubmitting && <GridContainer justify="space-between">

                {this.props.values.data.total > 0 &&
                  this.props.values.data.rows.map((ac) => {
                    return (
                      <GridItem key={`ac-${ac.id}`} xs={12} md={6}>
                        <ActivityCard
                          activity={ac}
                          afterSave={this.props.refreshHeader}
                          {...this.props}
                        />
                      </GridItem>
                    );
                  })}
              </GridContainer>}
              <GridContainer>
                <GridItem
                  xs={10}
                  md={12}
                  sm={12}
                  className={classNames(classes.mlAuto, classes.mrAuto)}
                >


                  {this.props.values.data.total > 0  && !isSubmitting  && (
                    <Pagination
                      style={{ float: 'right' }}
                      showFirstButton
                      showLastButton
                      page={parseInt(this.props.values.page + 1)}
                      count={Math.ceil(values.data.total / values.limit)}
                      onChange={async (e, page) => {
                        await this.props.setFieldValue("page", page - 1);
                        this.props.handleSubmit(e);
                        window.scrollTo(0, 0);
                        document.body.scrollTop = 0;
                      }}
                    />
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

SectionProducts.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(SectionProducts);
