/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {withRouter} from 'react-router-dom';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Pagination from '@material-ui/lab/Pagination';

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import Primary from "components/Typography/Primary";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle";
import Form from "PNH/views/SearchPage/Sections/FiltersForm/Form";
import ActivityCard from "PNH/components/Activity/ActivityCard";
import { ActivitiesFunctions } from "./index";

class SectionProducts extends React.Component {
  componentDidMount() {
    ActivitiesFunctions.index(this.props.values, this.props);
  }
  render() {
    const { classes, values } = this.props;

    return (<>
          <GridContainer>
            <GridItem md={3} sm={3}>
              <Form {...this.props} />
            </GridItem>
            <GridItem md={9} sm={9} id="search-box">
              <GridContainer justify="space-between">
                {this.props.values.data.total === 0 && (
                  <GridItem xs>
                    <Primary>
                      <h4>
                        <b>Brak zajęć</b>
                      </h4>
                    </Primary>
                    <br />
                    <h6>
                      Znajdź coś dla twojego dziecka <a href="/szukaj?with_tags=dla-dzieci">TUTAJ</a>
                    </h6>
                  </GridItem>
                )}
                {this.props.values.data.total > 0 &&
                  this.props.values.data.rows.map((ac) => {
                    return (
                      <GridItem key={`ac-${ac.id}`} xs={12} md={6}>
                        <ActivityCard activity={ac} {...this.props} />
                      </GridItem>
                    );
                  })}
              </GridContainer>

            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem
                xs={12}

            >
              {this.props.values.data.total > 0 && (
                  <Pagination
                      style={{float: "right"}}
                      showFirstButton
                      showLastButton
                      page={parseInt(this.props.values.page+1)}
                      count={Math.ceil(values.data.total/values.limit)}
                      onChange={async (e, page) => {
                        await this.props.setFieldValue("page", page-1);
                        this.props.handleSubmit(e);
                      }}
                  />
              )}
            </GridItem>
          </GridContainer>
        </>
    );
  }
}

SectionProducts.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(withStyles(styles)(SectionProducts));
