import React, {useCallback, useEffect, useState} from "react";
import {Api} from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import {UsersFunctions} from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import PlayersTable from "./Table";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const Form = ({...props }) => {

    const {values, setFieldValue, handleSubmit} = props
    const [competitionGroupId, setCompetitionGroupId] = useState(props.match.params.competition_group_id)
    const [id, setId] = useState(props.match.params.id)
    const [group, setGroup] = useState({})
    const [team, setTeam] = useState({})


    useEffect(() => {
        if (competitionGroupId) {
            Api.competition_groups.show(competitionGroupId).then((response) => {
                if (response.ok) {
                    setGroup(response.data)
                } else {
                    props.history.push("/404")
                }
            });
        }
    }, [])

    const fetchTeam = useCallback(() => {
        if (competitionGroupId) {
            Api.competition_groups.teams.show(id, competitionGroupId).then((response) => {
                if (response.ok) {
                    setTeam(response.data)
                    setFieldValue('users', response.data.users)
                } else {
                    props.history.push("/404")
                }
            });
        }
    }, [])

    useEffect(fetchTeam, [group])

    const genders = {females: "female", males: 'male'}
    const playersLimitations = team.gender === 'unisex' ? { with_competition_group_id: competitionGroupId,} : { with_competition_group_id: competitionGroupId, with_gender: genders[team.gender]}


    return <GridContainer>
        <GridItem xs={12}>
            <h6>Lista graczy ({values.users.length}/{group.max_players_per_team})</h6>
            <GridContainer jusify="space-between">
                <GridItem xs={12} md={9}>
                    <AsyncSelector
                        apiMethod={(q) =>
                            Api.users.index({
                                ...playersLimitations,
                                ...q,
                            })
                        }
                        disabled={values.users.length >= group.max_players_per_team}
                        labelKey="label"
                        label="Zaproś zgraczy"
                        name="user"
                        value={
                            values.user && {
                                id: values.user.id,
                                label: values.user.label,
                            }
                        }
                        onChange={(value, data) => {
                            setFieldValue("user", data);
                            setFieldValue("user_id", value);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} md={3}>
                    <Button
                        color="primary"
                        fullWidth={true}
                        disabled={!values.user_id || values.users.length >= group.max_players_per_team}
                        onClick={async (e) => {
                            await setFieldValue("action", "add");
                            handleSubmit(e);
                        }}
                    >
                        Dodaj do drużyny
                    </Button>
                </GridItem>

                <GridItem xs={12}>
                    <PlayersTable id={id} competitionGroupId={competitionGroupId} key={`p-${values.users.length}`} admin={group.role === "admin"}
                                  usersFunctions={UsersFunctions} formik={props}/>
                </GridItem>
            </GridContainer>
        </GridItem>
    </GridContainer>
}

