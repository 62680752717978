import React, {useEffect, useState} from "react";
import GridContainer from "../../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../../components/Grid/GridItem";
import CustomInput from "../../../../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import {RecordVoiceOver} from "@material-ui/icons";
import EventIcon from "@material-ui/icons/Event";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import Button from "../../../../../../components/CustomButtons/Button";
import PersonIcon from "@material-ui/icons/Person";
import {hasUserRole} from "../../../../../services/Helpers";
import {UsersFunctions} from "./index"
import Danger from "../../../../../../components/Typography/Danger";

import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import DialpadIcon from "@material-ui/icons/Dialpad";
import {Api} from "../../../../../services/Api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import ErrorIcon from '@material-ui/icons/Error';
import Warning from "../../../../../../components/Typography/Warning";
import Card from "../../../../../../components/Card/Card";
import CardBody from "../../../../../../components/Card/CardBody";

const useStyles = makeStyles(basicsStyle);


export const Form = ({
                         values,
                         errors,
                         handleChange,
                         setFieldValue,
                         handleSubmit,
                         ...props
                     }) => {

    const [id, setID] = useState(props.id)
    const [disableSender, setDisableSender] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        if (id) {
            UsersFunctions.show(id, props).then(r => {
                setDisabled(!hasUserRole(r.data, "user_role_edit_profile"))
            })
        }
    }, []);

    return <form onSubmit={handleSubmit}>
        <GridContainer>
            <GridItem xs={12} style={{marginBottom: '20px'}}>
                <h5><strong>Dane osobowe</strong></h5>
            </GridItem>

            {disabled && <GridItem xs={12} style={{textAlign: 'center', marginTop: "-15px"}}>
                <Card>
                    <CardBody>
                        <Warning>
                            Na tym koncie znajdują się rezerwacje zajęć. W celu zmiany danych, innych niż email, identyfikator oraz
                            miasto, prosimy o <Link to={"s/kontakt"}> kontakt z obsługą klienta</Link>.
                        </Warning>
                    </CardBody>
                </Card>
            </GridItem>}

            {id && <GridItem xs={12}>
                <CustomInput
                    noErrorIcon
                    labelText={<span>Identyfikator konta<Danger>*</Danger></span>}
                    error={errors.identifier}
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: values.identifier,
                        name: "identifier",
                        onChange: handleChange,
                        autocomplete: "new-pidentifier",
                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                <PersonIcon className={classes.inputAdornmentIcon}/>
                            </InputAdornment>
                        ),
                    }}
                />
            </GridItem>}

            <GridItem xs={12}>
                <CustomInput
                    labelText=<span>Email</span>
                error={errors.email}
                formControlProps={{
                fullWidth: true,
            }}
                inputProps={{
                value: values.email,
                name: "email",
                onChange: handleChange,
                type: "email",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                        <Email className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                ),
            }}
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CustomInput
                    labelText=<span>Imię <Danger>*</Danger></span>
                error={errors.first_name}
                formControlProps={{
                fullWidth: true,
            }}
                inputProps={{
                value: values.first_name,
                name: "first_name",
                disabled: disabled,
                onChange: handleChange,
                type: "text",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                        <Face className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                )
            }}

                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CustomInput
                    labelText=<span>Nazwisko <Danger>*</Danger></span>
                error={errors.last_name}
                formControlProps={{
                fullWidth: true,
            }}
                inputProps={{
                value: values.last_name,
                name: "last_name",
                onChange: handleChange,
                disabled: disabled,
                type: "text",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                        <RecordVoiceOver className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                ),
            }}
                />
            </GridItem>

            <GridItem xs={12} md={6}>
                <CustomInput
                    labelText=<span>Data Urodzenia <Danger>*</Danger></span>
                error={errors.birth_date}
                formControlProps={{
                fullWidth: true,
            }}
                inputProps={{
                placeholder: "RRRR-MM-DD",
                value: values.birth_date,
                name: "birth_date",
                onChange: handleChange,
                disabled: disabled,
                type: "text",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                        <EventIcon className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                ),
            }}
                />
            </GridItem>
            <GridItem xs={12} md={6}>
                <CustomInput
                    labelText=<span>Miasto <Danger>*</Danger></span>
                error={errors.city}
                formControlProps={{
                fullWidth: true,
            }}
                inputProps={{
                value: values.city,
                name: "city",
                onChange: handleChange,
                type: "text",
                endAdornment: (
                    <InputAdornment position="end" className={classes.inputAdornment}>
                        <LocationCityIcon className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                ),
            }}
                />
            </GridItem>

            <GridItem xs={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                    >
                        <span>Płeć <Danger>*</Danger></span>
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        value={values.gender}
                        onChange={handleChange}
                        disabled={disabled}
                        inputProps={{
                            name: "gender",
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="female"
                        >
                            Kobieta
                        </MenuItem>
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="male"
                        >
                            Mężczyzna
                        </MenuItem>
                    </Select>
                </FormControl>
            </GridItem>

            <GridItem xs={12}>
                <Danger>* pola wymagane</Danger>
            </GridItem>

            <GridItem xs={12} style={{marginBottom: '20px', marginTop: '50px'}}>
                <h5><strong>Zgody</strong></h5>
                <p>Rejestracja osób niepełnoletnich wymaga zgody rodzica lub opiekuna prawnego. Na numer telefonu podany
                    podczas rejestracji konta rodzica / prawnego opiekuna zostanie wysłany SMS z kodem potwierdzającym
                    zgodę i tożsamość.</p>
            </GridItem>

            <GridItem xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            required={!values.id}
                            disabled={disabled}
                            error={errors.child_policy_accepted}
                            tabIndex={-1}
                            checked={values.child_policy_accepted}
                            onClick={() => {
                                setFieldValue(
                                    "child_policy_accepted",
                                    !values.child_policy_accepted
                                )
                            }}
                            checkedIcon={
                                <Check className={classes.checkedIcon}/>
                            }
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    }
                    classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                    }}
                    label=<span>Wyrażam zgodę na udział mojego dziecka w cyklicznych zajęciach sportowych organizowanych
                    za pośrednictwem portalu PilkaNaHali.pl. Jednocześnie oświadczam, że moje dziecko nie ma
                    przeciwwskazań zdrowotnych ani medycznych do udziału w zajęciach sportowych, a jego stan zdrowia
                    pozwala na uczestnictwo w aktywnościach fizycznych. Wyrażam zgodę na przeprowadzenie wszelkich
                    niezbędnych zabiegów w stanach zagrażających życiu lub zdrowiu mojego dziecka.
                    <br/><br/>
                    Zobowiązuję się, że moje dziecko będzie stosować się do poleceń oraz instrukcji otrzymywanych od
                    trenera podczas treningów.
                    <br/><br/>
                    Jako rodzic/opiekun prawny zobowiązuje się do zapewnienia dziecku bezpiecznej drogi na treningi oraz
                    jego powrotu do domu. W przypadku, gdy nie odbiorę dziecka osobiście, wyrażam zgodę na jego
                    samodzielny powrót.
                    <br/><br/>
                    Oświadczam, że wyrażam zgodę na przetwarzanie naszych danych osobowych zgodnie z przepisami RODO, w
                    celu organizacji i realizacji zajęć sportowych.
                    <br/><br/>
                    Wyrażam także zgodę na utrwalanie i publikowanie zdjęć oraz nagrań wideo z treningów na stronach
                    internetowych oraz w mediach społecznościowych Organizatora zajęć.
                    <br/><br/>
                    Zgoda ta obowiązuje na cały okres posiadania konta dziecka w portalu PilkaNaHali.pl, bez
                    konieczności jej ponownego podpisywania przed każdym zapisem na zajęcia sportowe w portalu. Dopiero
                    wypowiedzenie powyższej zgody na piśmie i wysłanie jej na adres mailowy: kontakt@pilkanahali.pl
                    skutecznie ją wypowiada. Niniejsza zgoda została potwierdzona i złożona poprzez weryfikację SMS-em
                    na podany numer telefonu w profilu rodzica/opiekuna.</span>
                />
            </GridItem>

            {!values.wasAccepted &&
                <GridItem xs={12} style={{marginTop: '40px', fontWeight: '400'}}>
                    <GridContainer style={{backgroundColor: '#FFF6F7', padding: '20px', paddingLeft: '40px'}}>
                        <Danger><ErrorIcon style={{position: "absolute", top: '30px', left: '20px', fontSize: '16px'}}/></Danger>
                        <GridItem xs={12}>
                            <h6>
                                <strong>Weryfikacja tożsamości</strong>
                            </h6>
                            <p>
                                Aby zapisać powiązane konto na zajęcia wymagane jest potwierdzenie tożsamości przy
                                wyrażaniu
                                zgód prawnych.
                                Na numer telefonu {props.data.currentUser.phone} wysłaliśmy SMS z kodem potwierdzającym
                                tożsamość. Wpisz go w poniższe pole. Kod jest ważny przez 24 h.
                            </p>
                        </GridItem>

                        <GridItem xs={12} md={4}>
                            <CustomInput
                                labelText="Kod"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    value: values.token,
                                    name: "token",
                                    onChange: handleChange,
                                    type: "text",
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            <DialpadIcon className={classes.inputAdornmentIcon}/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} style={{marginTop: '20px'}}>
                            Kod nie dotarł?
                            <Button
                                disabled={disableSender}
                                color={"primary"}
                                simple={true}
                                size={"sm"}
                                onClick={() => {
                                    setDisableSender(true)
                                    Api.users.getToken().then(r => {
                                        if (r.ok) {
                                            window.setNotifications([
                                                {type: "success", msg: "Kod został wysłany"},
                                            ]);
                                        }
                                    });
                                    setTimeout(() => {
                                        setDisableSender(false)
                                    }, 3500);
                                }}
                            >
                                Wyślij nowy kod
                            </Button>
                        </GridItem>
                        <GridItem xs={12} style={{marginTop: '-10px'}}>
                            Zmiana numeru?
                            <Button
                                color={"primary"}
                                href={"/edit-profile"}
                                target={"_blank"}
                                simple={true}
                                size={"sm"}

                            >
                                Przejdź do ustawień konta
                            </Button>
                        </GridItem>

                    </GridContainer>
                </GridItem>
            }

            <GridItem xs={12}>

                <GridItem xs={12} style={{textAlign: 'center', marginTop: '50px'}}>
                    <Button type="submit" disabled={!values.id && (!values.child_policy_accepted || !values.token)}
                            color="primary"
                            round={true}>
                        {values.id && <>Zapisz zmiany</>}
                        {!values.id && <>Utwórz konto i potwierdź tożsamości</>}
                    </Button>
                </GridItem>

            </GridItem>
        </GridContainer>
    </form>

}