/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// eslint-disable-next-line
import React, {Suspense, useCallback, useEffect, useState} from "react";
import {createBrowserHistory} from "history";
// eslint-disable-next-line
import {Router} from "react-router";
import MainContainer from "./MainContainer";
import {Api} from "PNH/services/Api";
import 'moment/locale/pl'
import {Routes} from "./Routes";
import {isEqual} from "lodash";

var hist = createBrowserHistory();
var stateUpdates = {}

hist.listen((location) => {
    const ga_coockies = localStorage.getItem("ga_coockies")
    if(ga_coockies == 'true' || ga_coockies == null) {
        if(window.ga) {
            window.ga("set", "page", location.pathname + location.search);
            window.ga("send", "pageview");
        }
    }
});

const urlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return Object.fromEntries(urlParams)
}

const Application = ({...props}) => {
    const [currentUser, setCurrentUser] = useState({})
    const [settings, setSettings] = useState({})
    const [fetch, setFetch] = useState(true)

    useEffect(() => {
        init()
    }, []);

    const getCurrentUser = useCallback(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setFetch(false)
            return null;
        }
        Api.users.show("current").then((response) => {
            setCurrentUser(response.data)
            setFetch(false)
            if (response.data.deposit_alert) {
                window.setNotifications([
                    {
                        type: "alert",
                        msg: `Brak wymaganej kaucji na koncie. Uzupełnij kaucję do ${response.data.deposit_alert_date} aby uniknąć automatycznego wypisania z zajęć.`,
                    },
                ])
            }
        })
    }, []);

    const init = async () => {
        Api.settings.index({limit: 1000}).then((response) => {
            setSettings(response.data.rows)
            getCurrentUser()
        })

    }

    const getData = () => {
        return {currentUser: currentUser, urlParams: urlParams(), settings: settings, getCurrentUser: getCurrentUser}
    }


    const updateMasterState = (props, clb = () => {
    }) => {
        if (!isEqual(props.data.currentUser, currentUser)) {
            setCurrentUser(props.data.currentUser)
        }
        clb()
    };

    if (fetch) {
        return (<p></p>)
    }

    return (
        <MainContainer data={getData()} getStateUpdates={() => stateUpdates}>
            <Router history={hist}>
                <Routes state={{data: getData(), getStateUpdates: () => stateUpdates}}
                        updateMasterState={updateMasterState} {...props} />
            </Router>
        </MainContainer>
    );
}

export default Application