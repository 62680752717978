import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles";
import Card from "../../../../../components/Card/Card";
import {SubaccountForm} from "./Form";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    toggleModal = (open) => {
        this.setState({modal: open});
    };

    render() {
        const {
            classes,
        } = this.props;
        return <>
            <Button
                style={{float: "right", marginTop: '30px'}}
                color="primary"
                size={"sm"}
                onClick={() => {
                    this.toggleModal(true)
                }}
            >
                Stwórz konto
            </Button>
            <Dialog
                maxWidth={'md'}
                fullWidth={true}
                open={this.state.modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => this.toggleModal(false)}

            >
                <Card plain className={classes.modalSignupCard}>
                    <DialogTitle
                        disableTypography
                        className={classes.modalHeader}
                        style={{marginTop: "-40px"}}
                    >
                        <Button
                            justIcon
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={() => this.toggleModal(false)}
                        >
                            <Close className={classes.modalClose}/>
                        </Button>
                        <center>
                             <h4><strong>Stwórz konto</strong></h4>
                        </center>
                    </DialogTitle>
                    <DialogContent className={classes.modalBody}>
                        <SubaccountForm classes={classes} {...this.props} afterSave={() => {
                            this.props.afterSave();
                            this.toggleModal(false)
                        }}/>
                    </DialogContent>
                    <DialogActions  className={
                        classes.modalFooter + " " + classes.justifyContentCenter
                    }>
                        <GridContainer>
                            <GridItem xs={12} className={classes.textCenter}>
                                <Button
                                    simple
                                    color="transparent"
                                    onClick={() => this.toggleModal(false)}
                                >
                                    Anuluj
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </DialogActions>
                </Card>
            </Dialog>
        </>

    }
}

export default withStyles(javascriptStyles)(ModalForm);
