import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import {Api} from "PNH/services/Api";

export const UsersFunctions = {
    add: (values, formik) => {
        return Api.activities.team_line_ups.create(values.activity_id, {
            activity_team_line_up: {
                player_id: values.user_id, competition_team_id: values.team_id, activity_id: values.activity_id
            }
        })
            .then((response) => {
                if (response.ok) {
                    formik.setValues({
                        ...UsersFunctions.defaultValues(formik.props),
                        ...response.data,
                    });
                    UsersFunctions.currentTeamSize(response.data.rows, formik)
                    window.setNotifications([{type: "success", msg: "Dodano"}]);
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Nie udało się zapisać zmian"},
                    ]);
                }
            });
    },
    remove: (values, formik) => {
        return Api.activities.team_line_ups.delete(values.activity_id, values.row_id)
            .then((response) => {
                if (response.ok) {
                    formik.setValues({
                        ...UsersFunctions.defaultValues(formik.props),
                        ...response.data,
                    });
                    UsersFunctions.currentTeamSize(response.data.rows, formik)
                    window.setNotifications([{type: "success", msg: "Usunięto"}]);
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Nie udało się usunąć"},
                    ]);
                }
            });
    },

    currentTeamSize: (rows, formik) => {
        formik.props.masterFormSetFieldValue('current_team_size', rows.length)
    },

    defaultValues: (props) => {
        return {
            masterFormSetFieldValue: props.masterFormSetFieldValue,
            team_id: props.team_id,
            activity_id: props.activity.id,
            activity: props.activity,
            competition_group_id: props.competition_group_id,
            id: props.id,
            action: "add",
            user_id: "",
            row_id: "",
            rows: props.rows || [],
            gender: props.gender,
            user: {},
            afterSave: () => {
            },
        };
    },
};

export const PlayersForm = withFormik({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
        return {...UsersFunctions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        formikProps.setSubmitting(true);
        UsersFunctions[values.action](values, formikProps)
            .then((response) => {
                values.afterSave(response);
                formikProps.setFieldValue("afterSave", () => {
                });
            })
            .then(() => {
                formikProps.setTouched({});
            });
    },
})(Form);
