import React, {useEffect, useState} from "react";
import {Api} from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import stylesExtended from "PNH/assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import {UsersFunctions} from "./index";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const Form = ({
                  values, setValues, classes,
                  errors,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                    ...props
              }) => {


    useEffect(() => {
        Api.activities.team_line_ups.index(values.activity_id, {with_competition_team_id: values.team_id, limit: 100}).then((response) => {
            if (response.ok) {
                setFieldValue('rows', response.data.rows)
                UsersFunctions.currentTeamSize(response.data.rows, {props: {masterFormSetFieldValue: values.masterFormSetFieldValue}})
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Coś poszło nie tak"},
                ]);
            }
        });
    }, []);

    const genders = {females: "female", males: 'male'}

    const filters = ['females', 'males'].includes(values.gender) ? {
        with_competition_team_id: values.team_id,
        with_gender: genders[values.gender]
    } : {with_competition_team_id: values.team_id}

    return (
        <GridContainer>
            <GridItem xs={12}>
                <h6 style={{textAlign: "left", marginTop: '30px', marginBottom: '-10px'}}>Wybież graczy
                    ( {values.rows.length}/{values.activity.max_team_size} min. {values.activity.min_team_size})</h6>
            </GridItem>
            <GridItem xs={12}>
                <AsyncSelector
                    apiMethod={(q) =>
                        Api.competition_groups.teams.players(values.competition_group_id, values.team_id, {
                            ...filters,
                            ...q,
                        })
                    }
                    labelKey="label"
                    label="Szukaj zawodnika z drużyny"
                    name="user"
                    value={
                        values.user && {
                            id: values.user.id,
                            label: values.user.label,
                        }
                    }
                    onChange={async (value, data) => {
                        setFieldValue("user", data.user);
                        setFieldValue("user_id", data.user.id);
                        await setFieldValue("action", "add");
                        handleSubmit();
                    }}
                />
            </GridItem>
            {values.rows.length > 0 && <GridItem xs={12} style={{textAlign: 'left'}}>
                    {values.rows.map((row) => {
                        return <div style={{float: 'left', border: '1px solid #E0E6E3', marginRight: '10px', padding: '0px 8px', fontSize: '16px', borderRadius: '7px', height: '40px', whiteSpace: 'nowrap', marginBottom: '15px'}}>{row.player.label}<Button style={{marginLeft: '25px', marginTop: '4px'}}
                            justIcon
                            color="danger"
                            size="sm"
                            onClick={async (e) => {
                                await setFieldValue("action", "remove");
                                await setFieldValue("row_id", row.id);
                                handleSubmit(e);
                            }}
                        >
                            <CloseIcon/>
                        </Button></div>
                    })}


            </GridItem>}
        </GridContainer>)

}

export default withStyles(stylesExtended)(Form);
