/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, {useCallback, useEffect, useReducer, useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter, Link} from 'react-router-dom';
// @material-ui/icons

// core components
import Header from "PNH/components/Header/Header";

import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";


import {Footer} from "PNH/layouts/Footer";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle";

import {Api} from "PNH/services/Api";
import CoordinatorModal from "./CoordinatorModal/index";
import ListAltIcon from "@material-ui/icons/ListAlt";

import {SimilarActivitiesSlider} from "./ModalSteps/SimilarActivities";
import {SportLevelOpinionForm} from "./AddOpinionForm";
import {PricingBox} from "./PricingBox";
import {InfoSection} from "./sections/Info";
import {DescriptionSection} from "./sections/Description";
import {HeaderSection} from "./sections/Header";
import {ImagesSection} from "./sections/Images";


const addToWatched = (id) => {
    const lc = localStorage.getItem('lastWatched')
    const lastWatched = !!lc ? lc.split(',') : []
    if (id) {
        if (!lastWatched.includes(id.toString())) {
            lastWatched.unshift(id);
            localStorage.setItem('lastWatched', lastWatched.slice(0, 6).join(','))
        }
    }
}

const ActivityPage = ({data, classes, history, ...props}) => {
    const [params, _] = useState(new URLSearchParams(props.location.search))
    const [id, setID] = useState(params.get('with_id'))
    const [activity, setActivity] = useState({})
    const [version, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        if (!id) {
            const search = Object.values({
                ...data.urlParams,
                ...{controller: "", action: "", module: ""},
            });
            history.push("/szukaj?with_tags=" + search.join(" "))
        } else {
            fetch();
        }
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }, [id]);

    const fetch = useCallback(() => {
        const access_token = params.get('access_token') || "";
        Api.activities.show(id, access_token)
            .then(({data, ok}) => {
                if (ok) {
                    setActivity(data)
                    addToWatched(data.id)
                    forceUpdate()
                } else {
                    window.location.href = '/404'
                }
            });
    }, [id])

    return (
        <div className={classes.productPage} key={`v-${version}`}>
            <Header

                brand="PNH"
                fixed
                color="white"
                classes={classes}
                data={data}
                {...props}
            />
            <Parallax image={process.env.REACT_APP_API_URL + activity.baner_image} className={classes.pageHeader}/>
            <div className={classNames(classes.section, classes.sectionWhite)}>
                <div className={classes.container}>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <GridContainer justfy="space-between">
                                    <GridItem xs={12} md={6}>
                                        <HeaderSection activity={activity} classes={classes}/>
                                        <ImagesSection activity={activity}/>
                                        <GridContainer style={{marginTop: 20}}>
                                            <GridItem xs={12}>{
                                                activity.past && data.currentUser && data.currentUser.id &&
                                                <SportLevelOpinionForm currentUser={data.currentUser}
                                                                       activity_id={activity.id}
                                                                       activity={activity} {...props} />
                                            }</GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem
                                        sm
                                        xs={12}
                                        md={6}
                                    >
                                        {activity.id && activity.coordinated && (
                                            <Button
                                                color="primary"
                                                round
                                                onClick={() => {
                                                    window.location.href = `/lista-obecnosci/${activity.id}`;
                                                }}
                                                key={`edit_${activity.id}`}
                                                className={classes.actionButton}
                                            >
                                                Sprawdź listę obecności &nbsp; <ListAltIcon
                                                className={classes.icon}/>
                                            </Button>
                                        )}

                                        <PricingBox data={data} classes={classes} activity={activity}
                                                    updateHeader={fetch} {...props}/>
                                        <InfoSection activity={activity} classes={classes} {...props}/>
                                    </GridItem>

                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <DescriptionSection activity={activity}/>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className={classes.relatedProducts}>
                        <h3 className={classNames(classes.title, classes.textCenter)}>
                            Zobacz również
                        </h3>
                        <GridContainer>
                            <GridItem xs={12} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                <center>
                                    {activity.id && <SimilarActivitiesSlider key={`r4-${activity.id}`}
                                                                             activity={activity}
                                                                             userLevel={{level: activity.lv}}
                                                                             lgItems={3} xlItems={3}
                                                                             {...props}
                                                                             data={data}
                                                                             refreshHeader={forceUpdate}
                                                                             classes={classes}/>}
                                </center>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            {
                <CoordinatorModal
                    activity={activity}
                    afterSave={fetch}
                    data={data}
                />
            }
            <Footer classes={classes}
                    data={data}
                    {...props} />
        </div>
    );
}


ActivityPage.propTypes = {
    classes: PropTypes.object,
};

export default withRouter(withStyles(productStyle)(ActivityPage));
